import React from 'react'
import { Grid, Row, Col, Image, Typography } from 'antd'
import { StyledGillHeader } from './GillHeader.styles'
import { LayoutCentering } from '../../../components'
import LawSkillsLogo from '../images/logo-lawskills-white.png'

const GillHeader = ({ title }: { title: string }) => {
  const screens = Grid.useBreakpoint()

  return (
    <StyledGillHeader>
      <LayoutCentering>
        <Row align='middle'>
          <Col {...(screens.sm ? { flex: 'auto' } : { xs: 24 })}>
            <Typography.Title level={2}>{title}</Typography.Title>
          </Col>
          <Col {...(screens.sm ? {} : { xs: 24 })}>
            <Image src={LawSkillsLogo} preview={false} style={{ width: 'auto' }} height={screens.sm ? 60 : 30} />
          </Col>
        </Row>
      </LayoutCentering>
    </StyledGillHeader>
  )
}

export default GillHeader
