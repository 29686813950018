import { List as ListLibrary, Card } from 'antd'
import { rgba } from 'polished'
import { Link } from 'react-router-dom'
import styled, { DefaultTheme } from 'styled-components'
import { media } from '../../../styles'

const List = styled(ListLibrary)`
  position: relative;
  z-index: 1;
`

const Item = styled(ListLibrary.Item)`
  ${media.lg`
    margin-bottom: 0 !important;
  `}
` as any

Item.Inner = styled(Card)`
  width: 100%;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
  }

  .ant-card-body {
    display: flex;
    justify-content: space-between;

    ${media.xl`
      flex-direction: column;
      align-items: flex-start;
    `}
  }
`

Item.RegionIcon = styled.div<{ src: string }>`
  width: 3rem;
  height: 3rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
`

Item.More = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
  display: flex;
  justify-content: center;
  margin: 3.2rem 0;
`

Item.More.Inner = styled.a`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  display: flex;
  align-items: center;
  gap: 1.2rem;
  font-weight: 600;
  padding: 0 1.2rem;
  margin-bottom: -1.2rem;
`

Item.Info = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;

  ${media.xl`
    width: 100%;
  `}
`

Item.Info.Heading = styled.div`
  h4.ant-typography {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin: 0 0 2.8rem 0;
    gap: 1.2rem;
  }
`
Item.Info.Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};

  & > li,
  ol {
    padding-left: 2rem;
  }
`

Item.Info.Inner = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;

  ${media.xl`
    flex-direction: column;
    margin: 1.6rem 0 3.2rem 0;
  `}
`

Item.Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:first-child) {
    padding-left: 2.4rem;

    ${media.xl`
      padding-left: 0;
    `}
  }

  &:not(:last-child) {
    border-right: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
    padding-right: 2.4rem;

    ${media.xl`
      border-right: 0;
      border-bottom: 0.1rem solid ${({ theme }: { theme: DefaultTheme }) => theme.colors.gray[400]};
      padding-right: 0;
    `}
  }

  .ant-badge {
    margin-left: 0.8rem;
    cursor: pointer;
    &-count {
      background: ${({ theme }) => theme.colors.gray[400]};
      color: ${({ theme }) => theme.colors.gray[600]};
      font-weight: 500;
    }
  }

  ${media.xl`
    width: 100%;
    padding: 1.2rem 0 !important;
  `}
`

Item.Details.Name = styled.div`
  padding-left: 2.2rem;
  position: relative;
`

Item.Details.Icon = styled.div<{ src: string }>`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`

Item.Details.Label = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
`

Item.Details.Value = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-top: 0.4rem;
`

Item.Details.Truncate = styled.div`
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

Item.Watch = styled.div`
  .ant-btn {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`

Item.Speakers = styled.div`
  width: 32rem;
  border-left: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  padding-left: 4rem;
  margin-left: 4rem;

  ${media.xl`
    width: 100%;
    border: 0;
    padding-left: 0;
    margin-left: 0;
  `}
`

Item.Speakers.Group = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 2.8rem;
`

Item.Speakers.Name = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`
Item.Speakers.Link = styled(Link)`
  color: ${({ theme }) => theme.colors.gray[600]} !important;
`

Item.Speakers.Company = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  padding-top: 4px;
  text-transform: uppercase;
  white-space: normal;
`

Item.Speakers.Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`

Item.Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4rem;
  gap: 1.6rem;

  ${media.lg`
    margin: 2.4rem 0 0.8rem 0;
  `}

  .ant-btn {
    &[disabled] {
      flex: 1;
    }
  }
`

export { List, Item }
