import { statistics as consts } from '../constants'

const initialState = {
  isFetchingOverviewStatistics: false,
  overviewStatistics: {},
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const statisticsReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case consts.FETCHING_OVERVIEW_STATISTICS: {
      return {
        ...state,
        isFetchingOverviewStatistics: action.payload,
      }
    }

    case consts.RECEIVE_OVERVIEW_STATISTICS: {
      return {
        ...state,
        isFetchingOverviewStatistics: false,
        overviewStatistics: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default statisticsReducer
