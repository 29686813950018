import React, { useEffect, useState } from 'react'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { Grid } from 'antd'
import { useLocation, Link } from 'react-router-dom'
import { NotificationImportant, GoToMain, MainMenu, Ellipse } from '..'
import { Wrapper, Layout, Logotype } from './styled'
import { images } from '../../../assets'
import { useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'

const { useBreakpoint } = Grid

const Header = ({
  getProfileDetails,
  authLogout,
  getCounterStats,
  getPanelsSettings,
}: {
  getProfileDetails: () => void
  authLogout: () => void
  getCounterStats: () => void
  getPanelsSettings: () => void
}) => {
  const location = useLocation()
  const screens = useBreakpoint()

  const [menuToggle, setMenuToggle] = useState(false)

  const { isLogged } = useShallowEqualSelector((state: any) => state.auth)
  const { settings } = useShallowEqualSelector((state: any) => state.panels)

  useEffect(() => {
    getCounterStats()

    if (isLogged) {
      getProfileDetails()
      getPanelsSettings()
    }
  }, [])

  useEffect(() => {
    if (menuToggle) {
      setMenuToggle(false)
    }
  }, [screens])

  const handleToggleMenu = () => setMenuToggle(false)

  return (
    <Wrapper>
      {isLogged && settings.user_message_visible && <NotificationImportant content={settings.user_message} />}
      <GoToMain />

      <Layout pattern={location.pathname === '/' ? 0 : 1}>
        <Layout.Inner>
          <Link to='/'>
            <Logotype src={images.Logotype} />
          </Link>

          {!isLogged && (
            <Layout.SemiCircle>
              <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={8} rotate={-45} />
            </Layout.SemiCircle>
          )}

          <Layout.Menu>
            {!screens.xxl && (
              <Layout.Menu.Toggle onClick={() => setMenuToggle(!menuToggle)}>
                {menuToggle ? <CloseOutlined /> : <MenuOutlined />}
              </Layout.Menu.Toggle>
            )}

            <Layout.Menu.Inner visible={menuToggle}>
              <MainMenu
                handleMainMenu={handleToggleMenu}
                isOpen={menuToggle}
                isLogged={isLogged}
                onLogout={authLogout}
              />
            </Layout.Menu.Inner>
          </Layout.Menu>
        </Layout.Inner>
      </Layout>
    </Wrapper>
  )
}

export default Header
