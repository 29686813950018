import React from 'react'
import { Spin } from 'antd'
import { Layout } from './styled'

const Loader = ({ tip }: { tip?: string }) => (
  <Layout>
    <Spin tip={tip || 'Loading...'} />
  </Layout>
)

export default Loader
