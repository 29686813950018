import styled from 'styled-components'
import { media } from '../../../styles'

const Webinars = styled.div`
  background-color: #edf6fd;
  position: relative;
` as any

Webinars.CircleGreen = styled.div`
  position: absolute;
  top: 50%;
  left: -11rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.SemiRing = styled.div`
  position: absolute;
  right: -10rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.SemiCircle = styled.div`
  position: absolute;
  top: 25%;
  right: -4.4rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.List = styled.div`
  margin-bottom: 2.4rem;
`

// eslint-disable-next-line import/prefer-default-export
export { Webinars }
