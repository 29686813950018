import styled, { css } from 'styled-components'

const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.2rem 0;
` as any

Pagination.Prev = styled.a<{ blocked: boolean }>`
  font-size: 1.8rem;

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.blue[200]};
  }

  ${({ blocked }) =>
    blocked &&
    css`
      pointer-events: none;
      opacity: 0.25;
    `}
`

Pagination.Next = styled.a<{ blocked: boolean }>`
  font-size: 1.8rem;

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.blue[200]};
  }

  ${({ blocked }) =>
    blocked &&
    css`
      pointer-events: none;
      opacity: 0.25;
    `}
`

Pagination.Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`

Pagination.Dot = styled.a<{ active: boolean }>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  display: flex;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.red[200]};
    `}
`

// eslint-disable-next-line import/prefer-default-export
export { Pagination }
