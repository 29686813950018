import React from 'react'
import { Col, Grid, Image, Row } from 'antd'
import { Link } from 'react-router-dom'
import AwardImage from './images/badge-award.png'
import Award2024Image from './images/badge-awards-2024.png'
import Award2024Logo from './images/logo-awards-2024.png'
import AwardLogo from './images/logo-awards.png'
import LawLogo from './images/logo-lawskills.png'
import WomanImage from './images/woman.png'
import { StyledBox, StyledContainer, StyledContent, StyledCopy, StyledLogo } from './styled'
import { useShallowEqualSelector } from '../../../../../hooks'

const { useBreakpoint } = Grid

const MainHighlights = () => {
  const screens = useBreakpoint()
  const { isLogged } = useShallowEqualSelector((state: any) => state.auth)

  return (
    <StyledContainer>
      <StyledContent>
        <Row gutter={screens.lg ? [32, 32] : [16, 16]}>
          <Col md={24} xl={12}>
            <StyledBox>
              <Row wrap={false} gutter={40}>
                <Col>
                  <StyledLogo src={LawLogo} preview={false} />
                  <StyledCopy>
                    Estate Research Learning Hub has acquired content from Gill Steel’s Lawskills -{' '}
                    <Link to={isLogged ? '/gill-steel/webinars' : '/login'}>click here to access</Link>
                  </StyledCopy>
                </Col>
                {screens.xl && (
                  <Col>
                    <Image
                      src={WomanImage}
                      preview={false}
                      style={{ marginBottom: '-2rem', height: 200, width: 'auto' }}
                    />
                  </Col>
                )}
              </Row>
            </StyledBox>
          </Col>
          <Col md={24} xl={12}>
            <StyledBox>
              <Row wrap={!screens.lg} gutter={40}>
                <Col xs={24} flex='1'>
                  <StyledLogo src={AwardLogo} preview={false} />
                  <StyledCopy>
                    Estate Research Learning Hub wins the Best use of Technology award at the The British Wills &
                    Probate Awards 2023
                  </StyledCopy>
                </Col>
                <Col xs={24} flex={screens.sm ? 'none' : 'auto'} style={{ textAlign: 'center' }}>
                  <Image src={AwardImage} preview={false} style={{ width: 273, marginTop: screens.xl ? 0 : 24 }} />
                </Col>
              </Row>
            </StyledBox>
          </Col>
          <Col md={24}>
            <StyledBox>
              <Row wrap={!screens.lg} gutter={40}>
                <Col xs={24} flex='1'>
                  <StyledLogo src={Award2024Logo} preview={false} />
                  <StyledCopy>
                    Estate Research has been honoured with the <b>Probate Research Firm of the Year award</b> at the{' '}
                    <b>British Wills and Probate Awards 2024</b>. This accolade celebrates our unwavering dedication to
                    delivering high-quality research and exceptional service to both clients and beneficiaries
                    throughout the year.
                  </StyledCopy>
                </Col>
                <Col xs={24} flex={screens.sm ? 'none' : 'auto'} style={{ textAlign: 'center' }}>
                  <Image src={Award2024Image} preview={false} style={{ width: 273, marginTop: screens.xl ? 0 : 24 }} />
                </Col>
              </Row>
            </StyledBox>
          </Col>
        </Row>
      </StyledContent>
    </StyledContainer>
  )
}

export default MainHighlights
