import styled from 'styled-components'
import { images } from '../../../../../assets'
import { media } from '../../../../../styles'

const Register = styled.div`
  padding: 16rem 0;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 20rem;
    height: 20rem;
    background: url(${images.pictureKeyCircleLeft}) no-repeat center / contain;
    position: absolute;
    left: -2.8rem;
    bottom: -1.6rem;
  }

  h1.ant-typography {
    ${media.lg`
      font-size: 4rem;
    `}
  }

  ${media.lg`
    padding: 6rem 0;
  `}
` as any

Register.SemiRing = styled.div`
  position: absolute;
  left: -8.6rem;
  top: 8rem;

  ${media.lg`
    left: -3.6rem;
    top: 1.8rem;
  `}
`

Register.Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  ${media.lg`
    flex-direction: column;
  `}
`

Register.Details = styled.div`
  margin-right: 14rem;

  ${media.xl`
    margin-right: 6.4rem;
  `}

  ${media.lg`
    margin-right: 0;
  `}
`

Register.Text = styled.div`
  font-size: 1.6rem;
  margin-top: 3.6rem;

  ${media.lg`
    margin: 2rem 0 4rem 0;
  `}
`

Register.Form = styled.div`
  width: 100%;
  max-width: 40rem;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  box-shadow: 0 4rem 16rem -4.8rem rgba(0, 0, 0, 0.25);
  border-radius: 0.8rem;
  padding: 4rem;
  position: relative;

  .ant-btn {
    margin-top: 7.4rem;

    ${media.lg`
      margin-top: 4rem;
    `}
  }

  ${media.lg`
    max-width: 100%;
    padding: 2.4rem;
  `}
`

Register.Form.Pacman = styled.div`
  position: absolute;
  right: 3rem;
  top: -3rem;

  ${media.lg`
    display: none;
  `}
`

Register.Form.SemiCircle = styled.div`
  position: absolute;
  right: 6rem;
  bottom: -2rem;

  ${media.lg`
    display: none;
  `}
`

Register.Form.SemiRing = styled.div`
  position: absolute;
  left: -6rem;
  bottom: 8.8rem;

  ${media.lg`
    display: none;
  `}
`

Register.Form.Title = styled.div`
  line-height: 4.4rem;
  color: ${({ theme }) => theme.colors.red[200]};
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 2.8rem;

  ${media.lg`
    line-height: 4rem;
    font-size: 2.8rem;
    margin-bottom: 2rem;
  `}
`

Register.Form.Text = styled.div`
  font-size: 1.6rem;

  strong {
    font-weight: 700;
  }
`

// eslint-disable-next-line import/prefer-default-export
export { Register }
