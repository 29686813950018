import React, { useState, useEffect } from 'react'
import { UpOutlined } from '@ant-design/icons'
import { Grid, Space, Button, Image } from 'antd'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LogoLawSkills from './icons/logo-lawskills.png'
import { Menu } from './styled'
import { images } from '../../../assets'

const { useBreakpoint } = Grid

const menuList = [
  {
    name: 'Home',
    to: '/',
  },
  {
    name: 'Login',
    to: '/login',
  },
  {
    name: 'Create an Account',
    to: '/register',
  },
]

const menuLoggedList = [
  {
    name: 'Home',
    to: '/',
  },
  {
    name: 'Upcoming Webinars',
    to: '/upcoming-webinars',
  },
  {
    name: 'Previous Webinars',
    to: '/previous-webinars',
  },
  {
    name: 'In-Person Events',
    to: '/inperson-events',
  },
  {
    name: (
      <>
        Gill Steel
        <Image src={LogoLawSkills} width={63} height={16} preview={false} style={{ marginTop: -3 }} />
      </>
    ),
    new: 1,
    to: '/gill-steel/webinars',
    options: [
      {
        name: 'Webinars',
        to: '/gill-steel/webinars',
        icon: images.iconTablerVideo,
      },
      {
        name: 'Technical Learning Packs',
        to: '/gill-steel/technical-learning-packs',
        icon: images.iconTablerBook2,
      },
      {
        name: 'Monthly Digest',
        to: '/monthly-digest',
        icon: images.iconTablerCalendarEvent,
      },
    ],
  },
  {
    name: 'CPD Log',
    to: '/account/cpd-log',
  },
]

const MainMenu = ({
  onLogout,
  isLogged,
  handleMainMenu,
  isOpen,
}: {
  onLogout: () => void
  isLogged: boolean
  handleMainMenu: () => void
  isOpen: boolean
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const [dropdown, setDropdown] = useState<string>()

  const dropdownRef = useDetectClickOutside({ onTriggered: () => setDropdown(undefined) })

  useEffect(() => {
    if (dropdown) {
      setDropdown(undefined)
    }
  }, [screens])

  useEffect(() => {
    if (dropdown) {
      setDropdown(undefined)
    }
  }, [location.pathname])

  useEffect(() => {
    if (!isOpen) {
      setDropdown(undefined)
    }
  }, [isOpen])

  const menuAccountList = [
    {
      name: 'Overview',
      to: '/account/overview',
      icon: images.iconOverview,
    },
    {
      name: 'My Profile',
      to: '/account/profile',
      icon: images.iconProfile,
    },
    {
      name: 'Learning Hub History',
      to: '/account/learning-hub-history',
      icon: images.iconHistory,
    },
    {
      name: 'CPD Log',
      to: '/account/cpd-log',
      icon: images.iconLog,
    },
    {
      name: 'Log out',
      handler: onLogout,
      icon: images.iconLogout,
    },
  ]

  if (!isLogged) {
    if (screens.xxl) {
      return (
        <Space align='center' size='small'>
          {/* @ts-ignore */}
          <Button type='link-dark' onClick={() => navigate('/login')}>
            Login
          </Button>

          {/* @ts-ignore */}
          <Button type='ghost-dark' onClick={() => navigate('/register')} ghost>
            Create an account
          </Button>
        </Space>
      )
    }
    return (
      <Menu>
        <Menu.Inner>
          {menuList.map((item) => (
            <Menu.Item key={item.name} active={location.pathname === item.to}>
              <Link to={item.to}>{item.name}</Link>
            </Menu.Item>
          ))}
        </Menu.Inner>
      </Menu>
    )
  }

  return (
    <Menu ref={dropdownRef}>
      <Menu.Inner>
        {menuLoggedList.map((item) => {
          if (item.options) {
            return (
              <Menu.Item key={item.name}>
                {/* @ts-ignore */}
                <Menu.Link onClick={() => setDropdown((current) => (current === item.name ? undefined : item.name))}>
                  {!!item.new && <Menu.Badge>NEW</Menu.Badge>}
                  {/* @ts-ignore */}
                  {item.name} <UpOutlined rotate={dropdown === item.name ? 0 : 180} />
                </Menu.Link>
                {/* @ts-ignore */}
                <Menu.Dropdown visible={dropdown === item.name}>
                  {item.options.map((subItem) => (
                    <Menu.Dropdown.Item key={subItem.name} onClick={handleMainMenu}>
                      <Menu.Dropdown.Icon src={subItem.icon} />

                      {subItem.to ? (
                        <Link to={subItem.to}>{subItem.name}</Link>
                      ) : (
                        // @ts-ignore
                        <Menu.Link onClick={subItem.handler}>{subItem.name}</Menu.Link>
                      )}
                    </Menu.Dropdown.Item>
                  ))}
                </Menu.Dropdown>
              </Menu.Item>
            )
          }

          return (
            <Menu.Item key={item.name} active={location.pathname === item.to} onClick={handleMainMenu}>
              <Link to={item.to}>{item.name}</Link>
            </Menu.Item>
          )
        })}
      </Menu.Inner>

      <Menu.Inner>
        {screens.xxl ? (
          <Button
            // ref={dropdownRef}
            // @ts-ignore
            type='ghost-dark'
            onClick={() => setDropdown((current) => (current === 'my-account' ? undefined : 'my-account'))}
            ghost
          >
            My account <UpOutlined rotate={dropdown === 'my-account' ? 0 : 180} />
          </Button>
        ) : (
          <Menu.Dropdown.Toggle
            onClick={() => setDropdown((current) => (current === 'my-account' ? undefined : 'my-account'))}
          >
            <span>My account</span>
            <UpOutlined rotate={dropdown ? 0 : 180} />
          </Menu.Dropdown.Toggle>
        )}

        <Menu.Dropdown visible={dropdown === 'my-account'}>
          {menuAccountList.map((item) => (
            <Menu.Dropdown.Item key={item.name} onClick={handleMainMenu}>
              <Menu.Dropdown.Icon src={item.icon} />

              {item.to ? (
                <Link to={item.to}>{item.name}</Link>
              ) : (
                <Menu.Link onClick={item.handler}>{item.name}</Menu.Link>
              )}
            </Menu.Dropdown.Item>
          ))}
        </Menu.Dropdown>
      </Menu.Inner>
    </Menu>
  )
}

export default MainMenu
