import { List as ListLibrary, Card, Button } from 'antd'
import Title from 'antd/lib/typography/Title'
import { rgba } from 'polished'
import styled from 'styled-components'

const Item = styled(ListLibrary.Item)`
  height: 100%;
` as any

Item.Title = styled(Title)`
  font-size: 20px !important;
  margin-bottom: 28px !important;
`

Item.Inner = styled(Card)`
  height: 100%;
  width: 100%;
  transition: box-shadow 0.3s ease;

  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &:hover {
    box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
  }
`

Item.SpeakersTitle = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #9a9cab;
  margin: 0 0 8px;
`

Item.Speakers = styled.div`
  margin-bottom: 30px;
`

Item.Speakers.Item = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.gray[600]};

  &:not(last-child) {
    margin-bottom: 4px;
  }
`

Item.Speakers.Name = styled.p`
  font-family: 'IBM Plex Sans';
  margin: 0;
`

Item.Info = styled.div`
  margin-bottom: 1.8rem;
`

Item.Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  }

  .ant-badge {
    margin-left: 0.8rem;

    &-count {
      background: ${({ theme }) => theme.colors.gray[400]};
      color: ${({ theme }) => theme.colors.gray[600]};
      font-weight: 600;
    }
  }
`

Item.Details.Name = styled.div`
  padding-left: 2.4rem;
  position: relative;
  margin-right: 1rem;
`

Item.Details.Icon = styled.div<{ src: string }>`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 0.2rem;
  transform: translateY(-50%);
`

Item.Details.Label = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  text-transform: uppercase;
`

Item.Details.Value = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.2rem;
`

Item.Details.Truncate = styled.div`
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

Item.Button = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`

// eslint-disable-next-line import/prefer-default-export
export { Item }
