import styled from 'styled-components'
import { media } from '../../../../../styles'

const StyledPreviousWebinars = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  padding: 10rem 0;
  position: relative;

  ${media.lg`
    padding: 3.2rem 0;
  `}
`

type PreviousWebinarsComponent = typeof StyledPreviousWebinars & {
  SemiRing: typeof SemiRing
  CircleGreen: typeof CircleGreen
  CircleViolet: typeof CircleViolet
  SemiCircle: typeof SemiCircle
  Header: typeof Header
}

const SemiRing = styled.div`
  position: absolute;
  left: -10rem;
  top: 35rem;

  ${media.lg`
    display: none;
  `}
`

const CircleGreen = styled.div`
  position: absolute;
  bottom: 16.6rem;
  left: -11rem;

  ${media.lg`
    display: none;
  `}
`

const CircleViolet = styled.div`
  position: absolute;
  top: 7.5rem;
  right: -10rem;

  ${media.lg`
    display: none;
  `}
`

const SemiCircle = styled.div`
  position: absolute;
  right: -4.8rem;
  bottom: 17.6rem;

  ${media.lg`
    display: none;
  `}
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6.4rem;

  h2.ant-typography {
    ${media.lg`
      font-size: 3.2rem;
    `}
  }

  ${media.lg`
    margin-bottom: 1.6rem;
  `}
`

const PreviousWebinars = StyledPreviousWebinars as PreviousWebinarsComponent

PreviousWebinars.SemiRing = SemiRing
PreviousWebinars.CircleGreen = CircleGreen
PreviousWebinars.CircleViolet = CircleViolet
PreviousWebinars.SemiCircle = SemiCircle
PreviousWebinars.Header = Header

// eslint-disable-next-line import/prefer-default-export
export { PreviousWebinars }
