import React, { useEffect } from 'react'
import { Row, Col, Typography } from 'antd'
import queryString from 'query-string'
import { Speakers } from './styled'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse, FilterBy, PaginationDots, SpeakersList } from '../../components'

const { Title } = Typography

const SpeakersPage = ({ getSpeakersList }: { getSpeakersList: any }) => {
  const { isFetchingSpeakersList, speakers } = useShallowEqualSelector((state: any) => state.speakers)

  useEffect(() => {
    const parseParams = queryString.parse(globalThis.location.search, { arrayFormat: 'bracket' })

    getSpeakersList({ ...parseParams, for: 'webinars' })
  }, [])

  useDocumentTitle('Speakers')

  return (
    <Speakers>
      <Speakers.CircleGreen>
        <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
      </Speakers.CircleGreen>

      <Speakers.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </Speakers.SemiRing>

      <Speakers.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </Speakers.SemiCircle>

      <LayoutCentering>
        <Speakers.Header>
          <Title level={2}>Speakers</Title>
        </Speakers.Header>

        <Row gutter={[32, 32]}>
          <Col xl={5} xs={24}>
            <FilterBy onRequest={getSpeakersList} visibleRegions context='speakers' />
          </Col>

          <Col xl={19} xs={24}>
            <SpeakersList loading={isFetchingSpeakersList} dataSource={speakers.list} />
            <PaginationDots onRequest={getSpeakersList} meta={speakers.meta} isScrollToTop />
          </Col>
        </Row>
      </LayoutCentering>
    </Speakers>
  )
}

export default SpeakersPage
