import { api } from '../../services'
import { counter as consts } from '../constants'

const getCounterStats = () => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_COUNTER_STATS,
      payload: true,
    })

    const { data } = await api.get('/counter')

    dispatch({
      type: consts.RECEIVE_COUNTER_STATS,
      payload: data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_COUNTER_STATS,
      payload: false,
    })
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getCounterStats }
