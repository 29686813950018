import queryString from 'query-string'
import { api } from '../../services'
import { speakers as consts } from '../constants'

const getSpeakersList = (query: any) => async (dispatch: any) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })
  const isFeatured = query && query.featured

  try {
    if (isFeatured) {
      dispatch({
        type: consts.FETCHING_FEATURED_SPEAKERS_LIST,
        payload: true,
      })
    } else {
      dispatch({
        type: consts.FETCHING_SPEAKERS_LIST,
        payload: true,
      })
    }

    const { data } = await api.get(`/speakers?${queryParams}`)

    if (isFeatured) {
      dispatch({
        type: consts.RECEIVE_FEATURED_SPEAKERS_LIST,
        payload: {
          list: data.data,
          meta: data.meta,
        },
      })
    } else {
      dispatch({
        type: consts.RECEIVE_SPEAKERS_LIST,
        payload: {
          list: data.data.sort((a: any, b: any) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            if (nameA < nameB) return -1
            if (nameA > nameB) return 1
            return 0
          }),
          meta: data.meta,
        },
      })
    }
  } catch {
    if (isFeatured) {
      dispatch({
        type: consts.FETCHING_FEATURED_SPEAKERS_LIST,
        payload: false,
      })
    } else {
      dispatch({
        type: consts.FETCHING_SPEAKERS_LIST,
        payload: false,
      })
    }
  }
}

const getSpeakersListFull = () => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_SPEAKERS_LIST_FULL,
      payload: true,
    })

    const { data } = await api.get('/all-speakers')

    dispatch({
      type: consts.RECEIVE_SPEAKERS_LIST_FULL,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_SPEAKERS_LIST_FULL,
      payload: false,
    })
  }
}

const getSpeakerDetails = (slug: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_SPEAKER_DETAILS,
      payload: true,
    })

    const { data } = await api.get(`/speakers/${slug}`)

    dispatch({
      type: consts.RECEIVE_SPEAKER_DETAILS,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_SPEAKER_DETAILS,
      payload: false,
    })
  }
}

export { getSpeakersList, getSpeakersListFull, getSpeakerDetails }
