import { blogPosts as consts } from '../constants'

const initialState = {
  isFetchingBlogPosts: false,
  all: {
    list: [],
    meta: {},
  },

  isFetchingBlogPostCategories: false,
  categories: [],

  isFetchingBlogPostDetails: false,
  details: {},
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const blogPostsReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case consts.FETCHING_BLOG_POSTS: {
      return {
        ...state,
        isFetchingBlogPosts: action.payload,
      }
    }

    case consts.RECEIVE_BLOG_POSTS: {
      return {
        ...state,
        isFetchingBlogPosts: false,
        all: action.payload,
      }
    }

    case consts.FETCHING_BLOG_POSTS_CATEGORIES: {
      return {
        ...state,
        isFetchingBlogPostCategories: action.payload,
      }
    }

    case consts.RECEIVE_BLOG_POSTS_CATEGORIES: {
      return {
        ...state,
        isFetchingBlogPostCategories: false,
        categories: action.payload.list,
      }
    }

    case consts.FETCHING_BLOG_POST_DETAILS: {
      return {
        ...state,
        isFetchingBlogPostDetails: action.payload,
      }
    }

    case consts.RECEIVE_BLOG_POST_DETAILS: {
      return {
        ...state,
        isFetchingBlogPostDetails: false,
        details: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default blogPostsReducer
