import React, { useEffect } from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { useNavigate, Link } from 'react-router-dom'
import { ResetPassword } from './styled'
import { animations } from '../../../assets'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { MessageProcess } from '../../components'

const { Title, Text, Paragraph } = Typography

const ResetPasswordPage = ({ authResetPassword, resetAuth }: { authResetPassword: any; resetAuth: any }) => {
  const navigate = useNavigate()

  const { isFetchingResetPassword, isResetPassword } = useShallowEqualSelector((state: any) => state.auth)

  const [form] = Form.useForm()

  useEffect(() => {
    return () => {
      resetAuth()
    }
  }, [])

  useDocumentTitle('Reset Password')

  const onFinish = (values: any) => authResetPassword(values)

  if (isResetPassword) {
    const email = form.getFieldValue('email')

    return (
      <MessageProcess
        title='We have sent you an email'
        animationData={animations.email}
        details={
          <Paragraph>
            Please follow the instructions we have sent you to <Text type='danger'>{email}</Text> for changing your
            password.
          </Paragraph>
        }
      />
    )
  }

  return (
    <ResetPassword>
      <ResetPassword.Heading>
        <Title level={2}>Change your password</Title>
      </ResetPassword.Heading>

      <Form form={form} onFinish={onFinish} autoComplete='off' layout='vertical'>
        <Form.Item
          label='Email address'
          name='email'
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please enter your email',
            },
          ]}
        >
          <Input placeholder='Enter your email address' />
        </Form.Item>

        <Form.Item noStyle>
          <Button
            style={{ marginTop: '1.6rem' }}
            type='primary'
            htmlType='submit'
            loading={isFetchingResetPassword}
            block
          >
            Reset password
          </Button>
        </Form.Item>

        <Button type='link' style={{ marginTop: '1.2rem' }} onClick={() => navigate('/login')} block>
          Cancel
        </Button>

        <ResetPassword.ToRegister>
          <span>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            If you no longer have access to the email address you signed up with, for security reasons you'll need to{' '}
          </span>
          <Text>
            <Link to='/register'>create a new account.</Link>
          </Text>
        </ResetPassword.ToRegister>
      </Form>
    </ResetPassword>
  )
}

export default ResetPasswordPage
