import React, { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Button, Form, Input, Modal, Checkbox, message, Radio, Space, Row, Col, Typography } from 'antd'
import { useShallowEqualSelector } from '../../../../hooks'
import { api } from '../../../../services'
import { ModalTitle, Separator, Training } from '../../../components/ModalReferACase/styled'

const ModalRequestAccess = ({ open, onCancel }: { open: boolean; onCancel: () => void }) => {
  const [form] = Form.useForm()

  const { isLogged, profileDetails } = useShallowEqualSelector((state: any) => state.auth)

  useEffect(() => {
    if (isLogged && profileDetails.id) {
      form.setFieldsValue({
        ...profileDetails,
      })
    }
  }, [isLogged, profileDetails.id, open])

  const submit = useMutation({
    mutationFn: async (values) => api.post('/forms/request-access', values),
    onSuccess: () => {
      message.success('Your request has been sent')
      form.resetFields()
      onCancel()
    },
  })

  return (
    <Modal
      // eslint-disable-next-line react/no-unescaped-entities
      title={<ModalTitle>Get access to all our TLP's</ModalTitle>}
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
      width={730}
    >
      <Training>
        <Typography.Paragraph>
          The Technical Learning Packs are only available to our Genealogy Clients. Please complete your details below
          and we will be in touch within 5-10 working days.
        </Typography.Paragraph>
        <Separator />
        <Form
          form={form}
          onFinish={(values) => {
            submit.mutate(values)
          }}
          autoComplete='off'
          layout='vertical'
        >
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Form.Item
                label='First name'
                name='first_name'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name',
                  },
                ]}
              >
                <Input placeholder='First Name' />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label='Last name'
                name='last_name'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your last name',
                  },
                ]}
              >
                <Input placeholder='Last Name' />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label='Company'
                name='company'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your company',
                  },
                ]}
              >
                <Input placeholder='Company' />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label='Position'
                name='job_title'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your position',
                  },
                ]}
              >
                <Input placeholder='Position' />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label='Email address'
                name='email'
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter your email',
                  },
                ]}
              >
                <Input placeholder='Your email address' />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label='Phone'
                name='phone'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone',
                  },
                ]}
              >
                <Input placeholder='Phone' />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label='Do you use Estate Research other services?'
            name='services'
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Radio.Group>
              <Space direction='vertical'>
                <Radio value='yes'>Yes</Radio>
                <Radio value='no'>No</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Separator />

          <Typography.Paragraph>
            Technical Learning packs are an asset of Estate Research, and the content must not be shared or copied.
          </Typography.Paragraph>

          <Form.Item
            style={{ marginBottom: 0 }}
            name='terms'
            valuePropName='checked'
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('The consent is required')),
              },
            ]}
          >
            <Checkbox>
              I agree to the{' '}
              <a
                href='/privacy-policy'
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: 'underline' }}
              >
                Terms and Conditions
              </a>
            </Checkbox>
          </Form.Item>

          <Form.Item
            name='consent'
            valuePropName='checked'
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('The consent is required')),
              },
            ]}
          >
            <Checkbox>
              By submitting this form you agree to be contacted by Estate Research. We will not pass on your details to
              third parties without your consent
            </Checkbox>
          </Form.Item>

          <Training.Submit>
            <Form.Item noStyle>
              <Typography.Paragraph type='secondary' style={{ fontSize: 12, fontWeight: 500, marginBottom: 0 }}>
                * Required information
              </Typography.Paragraph>

              <Button type='primary' htmlType='submit' loading={submit.isPending}>
                Submit
              </Button>
            </Form.Item>
          </Training.Submit>
        </Form>
      </Training>
    </Modal>
  )
}

export default ModalRequestAccess
