import React, { useRef } from 'react'
import { Modal } from 'antd'
import { Media, ModalTitle } from './styled'

const VideoModalContainer = ({ visible, onCancel }: { visible: boolean; onCancel: () => void }) => {
  const ref = useRef(null)

  const pauseVideo = () => {
    // @ts-expect-error no type
    ref.current.player.pause()
  }

  const handleCancel = async () => {
    pauseVideo()
    onCancel()
  }

  return (
    <Modal
      width='max-content'
      // @ts-expect-error dead prop?
      height='min-content'
      title={<ModalTitle>How to use the CPD Log</ModalTitle>}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      afterClose={handleCancel}
      centered
    >
      <Media
        ref={ref}
        width={668}
        className='main-media-iframe'
        video='https://vimeo.com/698574546'
        showTitle={false}
        showByline={false}
        controls
      />
    </Modal>
  )
}

export default VideoModalContainer
