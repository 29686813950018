import { Layout as LayoutLibrary } from 'antd'
import styled from 'styled-components'
import { images } from '../../../assets'
import { media } from '../../../styles'

const Layout = styled(LayoutLibrary.Footer)`
  position: relative;
` as any

Layout.SemiRingTop = styled.div`
  position: absolute;
  right: 6.6rem;
  top: -2.8rem;

  ${media.lg`
    right: 1.6rem;
    bottom: 1.6rem;
  `}
`

Layout.SemiRingBottom = styled.div`
  position: absolute;
  left: -3.6rem;
  bottom: 3.6rem;

  ${media.lg`
    display: none;
  `}
`

Layout.Inner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4rem 3.2rem 9rem 3.2rem;
  position: relative;
  overflow: hidden;
  z-index: 1;

  & > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    width: 20rem;
    height: 20rem;
    background: url(${images.pictureKeyCircleBlack}) no-repeat center / contain;
    position: absolute;
    right: -3.2rem;
    bottom: 0;

    ${media.lg`
      bottom: 14.3rem;
    `}
  }

  ${media.lg`
    flex-direction: column;
    padding: 0 1.6rem;
  `}
`

Layout.Panel = styled.div`
  color: ${({ theme }) => theme.colors.gray[100]};
  padding: 0 1.6rem;

  ${media.lg`
    padding: 0;
    margin-bottom: 2.4rem;
  `}
`

Layout.Panel.Icon = styled.div<{ src: string }>`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
`

Layout.Panel.Label = styled.div`
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`

Layout.Panel.Data = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-bottom: 0.4rem;
  gap: 1.2rem;

  a {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`

const FooterUnder = styled.div`
  background-color: ${({ theme }) => theme.colors.blue[300]};
  font-size: 1.2rem;
  padding: 1.6rem 3.2rem;
  position: relative;
  z-index: 1;

  a,
  span {
    color: ${({ theme }) => theme.colors.gray[100]};
    opacity: 0.6;
  }

  a {
    &:hover {
      opacity: 0.8;
    }
  }

  .ant-space {
    ${media.lg`
      align-items: flex-start;
    `}
  }

  .split-bottom {
    .ant-space-item-split {
      ${media.lg`
        display: none;
      `}
    }

    ${media.lg`
      flex-direction: column;
    `}
  }

  .split-wrapper {
    & > .ant-space-item-split {
      ${media.lg`
        display: none;
      `}
    }
  }

  ${media.lg`
    padding: 1.6rem;
  `}
`

const RadicalLogo = styled.a`
  display: flex;
  align-items: center;
  opacity: 1 !important;

  &:hover {
    text-decoration: none;
  }

  img {
    width: 7.5rem;
    height: auto;
    margin-left: 1.2rem;
  }
`

const Logotype = styled.img`
  width: 23.6rem;
  position: relative;
  z-index: 1;

  ${media.xl`
    width: 21.6rem;
  `}

  ${media.lg`
    margin: 3.2rem 0 4rem 0;
  `}
`

const Feedback = styled.div`
  background-color: #112745;
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[100]};
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.colors.gray[100]};
  padding: 2.4rem;
  margin-right: 20rem;

  ${media.xl`
    margin-right: 0;
  `}

  ${media.lg`
    border-radius: 0;
    padding: 2.4rem 1.6rem;
    margin: 0 -1.6rem;
  `}
` as any

Feedback.Title = styled.div`
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 3.2rem;

  ${media.lg`
    margin-bottom: 2.4rem;
  `}
`

Feedback.Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  a {
    color: ${({ theme }) => theme.colors.yellow[200]};
    font-weight: 600;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow[100]};
    }
  }

  .ant-typography {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`

Feedback.Icon = styled.div<{ src: string }>`
  width: 4.4rem;
  height: 4.4rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  flex-shrink: 0;
`

export { Layout, Logotype, FooterUnder, Feedback, RadicalLogo }
