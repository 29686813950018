import styled from 'styled-components'
import { media } from '../../../styles'

const ChangePassword = styled.div`
  max-width: 49rem;
  width: 100%;
` as any

ChangePassword.Heading = styled.div`
  margin-bottom: 6rem;

  h2.ant-typography {
    ${media.lg`
      font-size: 2.8rem;
    `}
  }

  span.ant-typography {
    font-size: 1.6rem;
  }

  ${media.lg`
    margin-bottom: 2.8rem;
  `}
`

// eslint-disable-next-line import/prefer-default-export
export { ChangePassword }
