import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate, useRoutes, useSearchParams } from 'react-router-dom'
import { authRoutes, mainRoutes, loggedRoutes } from './routes'
import { GlobalStyle } from './styles'

import 'antd/dist/antd.less'
import { useShallowEqualSelector } from './hooks'

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var gtag: any
}

const App = () => {
  const routing = useRoutes([authRoutes, mainRoutes, loggedRoutes])
  const location = useLocation()
  const navigate = useNavigate()
  const trackRef = useRef<string>()

  const [searchParam, setSearchParam] = useSearchParams()

  const { profileDetails, isLogged } = useShallowEqualSelector((state: any) => state.auth)

  useEffect(() => {
    if (globalThis.location.href !== trackRef.current) {
      trackRef.current = globalThis.location.pathname

      if (globalThis.gtag) {
        globalThis.gtag('set', 'page_path', globalThis.location.pathname)
        globalThis.gtag('event', 'page_view')
      }
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.search.includes('is_cancel') && !isLogged) {
      navigate(`/login/${location.search}`)
    }

    if (location.search.includes('is_cancel') && profileDetails?.id) {
      const userId = searchParam.get('user_id')!

      if (+userId === +profileDetails.id) {
        navigate(`/upcoming-webinars/${location.search}`)
      } else {
        setSearchParam((prevParams: any) => prevParams.delete())
      }
    }
  }, [location.search, profileDetails, isLogged])

  return (
    <>
      <GlobalStyle />
      {routing}
    </>
  )
}

export default App
