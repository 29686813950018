import React from 'react'
import { Typography, Button, Avatar, Tooltip, Badge } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Item } from './styled'
import { images } from '../../../assets'
import { date } from '../../../helpers'

const { Title, Paragraph } = Typography

const getCategoriesDetails = (list: any) => {
  const categories = list.map((category: any) => category.title)

  return {
    first: categories[0],
    more: categories.length > 1 ? categories.splice(1, categories.length) : null,
  }
}

const SpeakerItem = ({
  item,
  categoriesList,
  start,
  region,
  nostyle,
  hideRegion,
  bottomLine = false,
}: {
  item: any
  categoriesList?: any
  start?: any
  region?: any
  nostyle?: boolean
  hideRegion?: boolean
  bottomLine?: boolean
}) => {
  const navigate = useNavigate()

  const categories = getCategoriesDetails(categoriesList || [])

  return (
    <Item nostyle={nostyle ? 1 : 0}>
      <Item.Inner>
        <Item.Header>
          <Avatar size={60} src={item.image} />

          {!hideRegion && item.region && (
            <Item.Region>
              <Item.Region.Icon src={images.iconRegion} />
              <Item.Region.Value>{item.region.title}</Item.Region.Value>
            </Item.Region>
          )}
        </Item.Header>

        <Item.Body bottomLine={bottomLine}>
          <Title level={4} ellipsis={{ rows: 4 }}>
            {item.name}
          </Title>

          <Item.Body.Company>{item.company}</Item.Body.Company>
        </Item.Body>

        <Item.Description>
          {/* @ts-ignore */}
          <Paragraph level={4} ellipsis={{ rows: 6 }}>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </Paragraph>
        </Item.Description>

        <Button type='primary' onClick={() => navigate(`/speakers/${item.slug}`)} ghost block>
          Learn more
        </Button>

        {bottomLine && <Item.Line />}

        {categoriesList && (
          <Item.Info>
            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconDate} />
                <Item.Details.Label>Webinar date</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>{date.formatUTC(start)}</Item.Details.Value>
            </Item.Details>

            {region && (
              <Item.Details>
                <Item.Details.Name>
                  <Item.Details.Icon src={images.iconRegion} />
                  <Item.Details.Label>Region</Item.Details.Label>
                </Item.Details.Name>

                <Item.Details.Value>
                  <Item.Details.Truncate>{region.title}</Item.Details.Truncate>
                </Item.Details.Value>
              </Item.Details>
            )}

            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconCategory} />
                <Item.Details.Label>Category</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>
                <Item.Details.Truncate>{categories.first}</Item.Details.Truncate>

                {categories.more && (
                  <Tooltip title={categories.more.join(', ')}>
                    <Badge count={`+${categories.more.length}`} title='' />
                  </Tooltip>
                )}
              </Item.Details.Value>
            </Item.Details>
          </Item.Info>
        )}
      </Item.Inner>
    </Item>
  )
}

export default SpeakerItem
