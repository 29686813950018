import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from '../../../styles'

const Login = styled.div`
  max-width: 49rem;
  width: 100%;
` as any

Login.Back = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.2rem;
  position: absolute;
  top: 3.2rem;
  left: 3.2rem;

  .anticon {
    width: 3.2rem;
    height: 3.2rem;
    border: 0.1rem solid ${({ theme }) => theme.colors.red[200]};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.8rem;
  }

  ${media.lg`
    top: 10.4rem;
    left: 1.6rem;
  `}
`

Login.Heading = styled.div`
  margin-bottom: 6rem;

  h2.ant-typography {
    ${media.lg`
      font-size: 2.8rem;
    `}
  }

  span.ant-typography {
    font-size: 1.6rem;
  }

  ${media.lg`
    margin-bottom: 2.8rem;
  `}
`

Login.Error = styled.div`
  color: ${({ theme }) => theme.colors.red[200]};
  font-size: 1.2rem;
  margin-top: -1.6rem;
  margin-bottom: 2.4rem;
`

Login.ToRegister = styled.div`
  margin-top: 1.6rem;
  text-align: center;
`

// eslint-disable-next-line import/prefer-default-export
export { Login }
