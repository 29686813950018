import { Carousel as CarouselLibrary } from 'antd'
import styled, { css } from 'styled-components'

const Carousel = styled.div`
  user-select: none;
  position: relative;
` as any

Carousel.List = styled(CarouselLibrary)``

Carousel.Prev = styled.a<{ light: boolean }>`
  font-size: 2.4rem;
  position: absolute;
  left: 1.2rem;
  bottom: -1rem;
  transition: opacity 0.2s;
  z-index: 16;

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  ${({ light }) =>
    light &&
    css`
      color: ${({ theme }) => theme.colors.gray[100]} !important;

      &:hover {
        opacity: 0.7;
      }
    `}
`

Carousel.Next = styled.a<{ light: boolean }>`
  font-size: 2.4rem;
  position: absolute;
  right: 1.2rem;
  bottom: -1rem;
  transition: opacity 0.2s;
  z-index: 16;

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.blue[200]};
  }

  ${({ light }) =>
    light &&
    css`
      color: ${({ theme }) => theme.colors.gray[100]} !important;

      &:hover {
        opacity: 0.7;
      }
    `}
`

// eslint-disable-next-line import/prefer-default-export
export { Carousel }
