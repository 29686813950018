import styled from 'styled-components'
import { media } from '../../../../../styles'

const Categories = styled.div`
  padding: 10rem 0 8rem 0;
  position: relative;

  ${media.lg`
    padding: 4rem 0;
  `}
` as any

Categories.SemiRing = styled.div`
  position: absolute;
  top: 15rem;
  left: -13.5rem;

  ${media.lg`
    display: none;
  `}
`

Categories.SemiCircle = styled.div`
  position: absolute;
  right: -2.4rem;
  bottom: 11.8rem;

  ${media.lg`
    display: none;
  `}
`

Categories.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6.4rem;

  h2.ant-typography {
    ${media.lg`
      font-size: 3.2rem;
    `}
  }

  ${media.lg`
    margin-bottom: 1.6rem;
  `}
`

// eslint-disable-next-line import/prefer-default-export
export { Categories }
