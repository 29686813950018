import { List as ListLibrary, Card } from 'antd'
import { rgba } from 'polished'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'
import { media } from '../../../styles'

const Item = styled(ListLibrary.Item)`
  h4.ant-typography {
    height: 11.2rem;
    font-size: 2rem;
    margin: 0;
    flex: 1;
  }
` as any

Item.Inner = styled(Card)`
  width: 100%;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
  }
`

Item.Header = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.8rem;

  ${media.lg`
    flex-direction: column;
    align-items: flex-start;
    gap: 0rem;
  `}

  a {
    color: currentColor;
  }
`

Item.Header.Speakers = styled.div`
  border-left: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  padding: 0 2.8rem 0 2.8rem;

  ${media.lg`
    border: 0;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 0 0 0.5rem 0;
  `}
`

Item.Header.Name = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  margin-top: 2.8rem;
  min-height: 7rem;

  > * {
    font-weight: 400;
  }
`

Item.Header.Company = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  padding-top: 4px;
  font-size: 1rem;
  text-transform: uppercase;
`

Item.Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.4em;

  ${media.lg`
    flex-direction: column;
    align-items: flex-start;
  `}
`

Item.Info.Inner = styled.div`
  display: flex;
  align-items: center;

  ${media.lg`
    flex-direction: column;
  `}
`

Item.Details = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
    padding-right: 2rem;
    margin-right: 2rem;

    ${media.lg`
      border: 0;
      margin: 0 0 1.2rem 0;
      padding: 0;
    `}
  }

  ${media.lg`
    width: 100%;
  `}
`

Item.Details.Name = styled.div`
  padding-left: 2.2rem;
  position: relative;
`

Item.Details.Icon = styled.div<{ src: string }>`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`

Item.Details.Label = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  text-transform: uppercase;
`

Item.Details.Value = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 0.4rem;
`

Item.Watch = styled.div`
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    ${media.lg`
      width: 100%;
    `}
  }

  ${media.lg`
    width: 100%;
    margin-top: 2.8rem;
  `}
`

Item.Watch.Icon = styled(ReactSVG)`
  width: 2.4rem;
  height: 2.4rem;

  svg {
    width: 2.4rem;
  }
`

// eslint-disable-next-line import/prefer-default-export
export { Item }
