import React, { useState } from 'react'
import { UpOutlined, DownOutlined, RightOutlined, CheckOutlined } from '@ant-design/icons'
import { Typography, Button, Avatar, Badge, Tooltip } from 'antd'
import Truncate from 'react-truncate'
import { List, Item } from './styled'
import { images } from '../../../assets'
import { date } from '../../../helpers'
import { theme } from '../../../styles'

const { Title, Text, Paragraph } = Typography

const getCategoriesDetails = (item: any) => {
  const categories = item.categories.map((category: any) => category.title)

  return {
    first: categories[0],
    more: categories.length > 1 ? categories.splice(1, categories.length) : null,
  }
}

const WebinarsUpcomingWideList = ({
  registerToWebinar,
  cancelWebinarRegistration,
  loading,
  dataSource,
}: {
  registerToWebinar: (id: string) => void
  cancelWebinarRegistration: (id: string) => void
  loading?: boolean
  dataSource?: any
}) => {
  const [expandableIds, setExpandableIds] = useState<string[]>([])

  const handleTruncate = (exId: string) => {
    if (expandableIds.includes(exId)) {
      setExpandableIds(expandableIds.filter((id: string) => id !== exId))
    } else {
      setExpandableIds([...expandableIds, exId])
    }
  }

  return (
    <List
      // @ts-expect-error dead prop
      grid={{ column: 1, gutter: [0, 24] }}
      loading={loading}
      dataSource={dataSource}
      renderItem={(item: any) => {
        const categories = getCategoriesDetails(item)

        return (
          <Item>
            <Item.Inner>
              <Item.Info>
                <Item.Info.Heading>
                  <Title level={4}>
                    {item.region.icon && <Item.RegionIcon src={item.region.icon} />}
                    {item.title}
                  </Title>

                  {/* @ts-expect-error broken type */}
                  <Truncate
                    lines={!expandableIds.includes(item.id) && 3}
                    ellipsis={
                      <>
                        ...
                        <Item.More onClick={() => handleTruncate(item.id)}>
                          <Item.More.Inner>
                            read more <DownOutlined />
                          </Item.More.Inner>
                        </Item.More>
                      </>
                    }
                  >
                    <Item.Info.Description>
                      <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Item.Info.Description>
                  </Truncate>

                  {expandableIds.includes(item.id) && (
                    <Item.More onClick={() => handleTruncate(item.id)}>
                      <Item.More.Inner>
                        read less <UpOutlined />
                      </Item.More.Inner>
                    </Item.More>
                  )}
                </Item.Info.Heading>

                <Item.Info.Inner>
                  <Item.Details>
                    <Item.Details.Name>
                      <Item.Details.Icon src={images.iconDate} />
                      <Item.Details.Label>Webinar date</Item.Details.Label>
                    </Item.Details.Name>

                    <Item.Details.Value>
                      <Item.Details.Truncate>{date.formatUTC(item.start_time)}</Item.Details.Truncate>
                    </Item.Details.Value>
                  </Item.Details>

                  <Item.Details>
                    <Item.Details.Name>
                      <Item.Details.Icon src={images.iconDate} />
                      <Item.Details.Label>Webinar length</Item.Details.Label>
                    </Item.Details.Name>

                    <Item.Details.Value>
                      <Item.Details.Truncate>{item.duration} minutes</Item.Details.Truncate>
                    </Item.Details.Value>
                  </Item.Details>

                  <Item.Details>
                    <Item.Details.Name>
                      <Item.Details.Icon src={images.iconRegion} />
                      <Item.Details.Label>Region</Item.Details.Label>
                    </Item.Details.Name>

                    <Item.Details.Value>
                      <Item.Details.Truncate>{item.region.title}</Item.Details.Truncate>
                    </Item.Details.Value>
                  </Item.Details>

                  <Item.Details>
                    <Item.Details.Name>
                      <Item.Details.Icon src={images.iconCategory} />
                      <Item.Details.Label>Category</Item.Details.Label>
                    </Item.Details.Name>

                    <Item.Details.Value>
                      <Item.Details.Truncate>{categories.first}</Item.Details.Truncate>

                      {categories.more && (
                        <Tooltip title={categories.more.join(', ')}>
                          <Badge count={`+${categories.more.length}`} title='' />
                        </Tooltip>
                      )}
                    </Item.Details.Value>
                  </Item.Details>
                </Item.Info.Inner>
              </Item.Info>

              <Item.Speakers>
                <Item.Speakers.Group>
                  <Avatar.Group
                    maxCount={3}
                    size={52}
                    maxStyle={{
                      backgroundColor: theme.colors.lightBlue[200],
                      fontSize: '1.4rem',
                      fontWeight: 600,
                    }}
                  >
                    {item.speakers.map((speaker: any) => (
                      <Avatar key={speaker.image} src={speaker.image} />
                    ))}
                  </Avatar.Group>

                  {item.speakers.length > 0 && (
                    <Item.Speakers.Name>
                      <Text strong>
                        {item.speakers.length > 1 ? (
                          'Multiple Speakers'
                        ) : (
                          <Item.Speakers.Link to={`/speakers/${item.speakers[0].slug}`}>
                            {item.speakers[0].name}
                          </Item.Speakers.Link>
                        )}
                      </Text>
                      <Item.Speakers.Company>{item.speakers[0].company}</Item.Speakers.Company>
                    </Item.Speakers.Name>
                  )}
                </Item.Speakers.Group>

                {item.speakers.length > 0 && (
                  <Item.Speakers.Description>
                    <Paragraph ellipsis={{ rows: 4 }}>
                      <div dangerouslySetInnerHTML={{ __html: item.speakers[0].description }} />
                    </Paragraph>
                  </Item.Speakers.Description>
                )}

                {item.register_status === 0 && (
                  <Item.Actions>
                    <Button type='primary' onClick={() => registerToWebinar(item.id)} ghost block>
                      Register <RightOutlined />
                    </Button>
                  </Item.Actions>
                )}
                {item.register_status === 1 && (
                  <Item.Actions>
                    <Button type='primary' loading disabled>
                      Register <RightOutlined />
                    </Button>
                  </Item.Actions>
                )}
                {item.register_status === 2 && (
                  <Item.Actions>
                    <Button type='primary' onClick={() => cancelWebinarRegistration(item.id)} ghost>
                      Cancel
                    </Button>

                    <Button className='ant-btn ant-btn-primary' disabled>
                      Registered <CheckOutlined />
                    </Button>
                  </Item.Actions>
                )}
              </Item.Speakers>
            </Item.Inner>
          </Item>
        )
      }}
    />
  )
}

export default WebinarsUpcomingWideList
