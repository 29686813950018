import { api } from '../../services'
import { statistics as consts } from '../constants'

const getOverviewStatistics = () => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_OVERVIEW_STATISTICS,
      payload: true,
    })

    const { data } = await api.get('/statistics')

    dispatch({
      type: consts.RECEIVE_OVERVIEW_STATISTICS,
      payload: data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_OVERVIEW_STATISTICS,
      payload: false,
    })
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getOverviewStatistics }
