import styled from 'styled-components'
import { media } from '../../../styles'

const Logout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12rem 1.6rem;

  ${media.lg`
    padding-top: 6rem;
    padding-bottom: 6rem;
  `}
`

// eslint-disable-next-line import/prefer-default-export
export { Logout }
