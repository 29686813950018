import styled from 'styled-components'
import { images } from '../../../../../assets'
import { media } from '../../../../../styles'

const Media = styled.div`
  padding: 10rem 0;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 38rem;
    height: 38rem;
    background: url(${images.pictureKeyCircleLeft}) no-repeat center / contain;
    position: absolute;
    left: -7rem;
    bottom: -3.6rem;

    ${media.lg`
      width: 30rem;
      height: 30rem;
      bottom: initial;
      top: 15rem;
    `}
  }

  ${media.lg`
    padding: 6rem 0;
  `}

  ${media.sm`
    padding: 3rem 0;
  `}
` as any

Media.Inner = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 3.2rem;
  position: relative;
  z-index: 1;

  ${media.lg`
    flex-direction: column;
    gap: 0;
  `}
`

Media.Details = styled.div`
  flex: 1;
  h2.ant-typography {
    margin-bottom: 4.8rem;

    ${media.lg`
      margin-bottom: 3.2rem;
    `}
  }

  h4.ant-typography {
    line-height: 4rem;
    font-weight: 300;
    margin-bottom: 3.2rem;

    strong {
      color: ${({ theme }) => theme.colors.lightBlue[200]};
      font-weight: 600;
    }

    ${media.lg`
      line-height: 3.2rem;
      font-size: 2.4rem;
    `}
  }

  div.ant-typography {
    font-size: 1.6rem;

    &-secondary {
      color: ${({ theme }) => theme.colors.blue[200]};
      font-size: 2rem;
      font-weight: 600;
    }

    strong {
      color: ${({ theme }) => theme.colors.red[200]};
      font-weight: 600;
    }
  }
`

Media.Video = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .main-media-iframe {
    width: 100%;
    iframe {
      width: 62.4rem;
      height: 36rem;

      ${media.lg`
        width: 34rem;
        height: 20rem;
      `}

      ${media.xs`
        width: 100%;
      `}
    }
  }

  ${media.lg`
    width: 34rem;
    margin: 1.4rem auto 0 auto;
  `}

  ${media.xs`
    width: 100%;
  `}
`

Media.Plants = styled.div`
  width: calc(100% + 12rem);
  position: absolute;
  left: -6rem;
  bottom: -10.8rem;

  ${media.lg`
    bottom: -6.8rem;
  `}

  ${media.sm`
    width: calc(100% + 8.5rem);
    left: -4rem;
  `}
`

Media.Video.Play = styled.a`
  width: 10.8rem;
  height: 10.8rem;
  background: url(${images.videoPlay}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 2;

  &:hover {
    opacity: 0.9;
  }
`

Media.Video.Overflow = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

// eslint-disable-next-line import/prefer-default-export
export { Media }
