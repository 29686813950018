import { List as ListLibrary, Card } from 'antd'
import { rgba } from 'polished'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

const Item = styled(ListLibrary.Item)`
  h4.ant-typography {
    height: 11.2rem;
    font-size: 2rem;
    margin: 2rem 0 2.4rem 0;
  }
` as any

Item.Inner = styled(Card)`
  width: 100%;

  && {
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }
`

Item.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

Item.Header.Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

Item.Header.Name = styled.div`
  display: flex;
  flex-direction: column;
`

Item.Header.Company = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  padding-top: 4px;
  text-transform: uppercase;
`

Item.Progress = styled.div`
  position: relative;

  .ant-progress {
    position: relative;
    z-index: 1;

    &-text {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }

  &:after {
    content: '';
    width: calc(100% - 0.4rem);
    height: calc(100% - 0.4rem);
    background-color: ${({ theme }) => rgba(theme.colors.green[200], 0.2)};
    border-radius: 50%;
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
  }
`

Item.Watch = styled.div`
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`

Item.Watch.Icon = styled(ReactSVG)`
  width: 2.4rem;
  height: 2.4rem;

  svg {
    width: 2.4rem;
  }
`

// eslint-disable-next-line import/prefer-default-export
export { Item }
