import { history as consts } from '../constants'

const initialState = {
  isFetchingLogs: false,
  log: [],

  isFetchingCPDLogs: false,
  cpdLog: [],

  isFetchingCPDLogPDF: false,

  isFetchingCreateCPDLog: false,
  isFetchingRemoveCPDLog: false,
  isFetchingUpdateCommentCPDLog: false,
  isFetchingStoreLog: false,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const historyReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case consts.FETCHING_HISTORY_CPD_LOG_PDF: {
      return {
        ...state,
        isFetchingCPDLogPDF: action.payload,
      }
    }
    case consts.FETCHING_HISTORY_LOG: {
      return {
        ...state,
        isFetchingLogs: action.payload,
      }
    }

    case consts.RECEIVE_HISTORY_CPD_LOG_PDF: {
      return {
        ...state,
        isFetchingCPDLogPDF: action.payload,
      }
    }

    case consts.RECEIVE_HISTORY_LOG: {
      return {
        ...state,
        isFetchingLogs: false,
        log: action.payload,
      }
    }

    case consts.FETCHING_HISTORY_CPD_LOG: {
      return {
        ...state,
        isFetchingCPDLogs: action.payload,
      }
    }

    case consts.RECEIVE_HISTORY_CPD_LOG: {
      return {
        ...state,
        isFetchingCPDLogs: false,
        cpdLog: action.payload,
      }
    }

    case consts.FETCHING_CREATE_CPD_LOG: {
      return {
        ...state,
        isFetchingCreateCPDLog: action.payload,
      }
    }

    case consts.RECEIVE_CREATE_CPD_LOG: {
      return {
        ...state,
        isFetchingCreateCPDLog: false,
        cpdLog: [...state.cpdLog, action.payload].sort(
          (logA: any, logB: any) => new Date(logB.completion_time).getTime() - new Date(logA.completion_time).getTime(),
        ),
      }
    }

    case consts.FETCHING_REMOVE_CPD_LOG: {
      return {
        ...state,
        isFetchingRemoveCPDLog: action.payload,
      }
    }

    case consts.RECEIVE_REMOVE_CPD_LOG: {
      return {
        ...state,
        isFetchingRemoveCPDLog: false,
        cpdLog: state.cpdLog.filter((item: any) => item.id !== action.payload.id),
      }
    }

    case consts.FETCHING_UPDATE_COMMENT_CPD_LOG: {
      return {
        ...state,
        isFetchingUpdateCommentCPDLog: action.payload,
      }
    }

    case consts.RECEIVE_UPDATE_COMMENT_CPD_LOG: {
      return {
        ...state,
        isFetchingUpdateCommentCPDLog: false,
        cpdLog: state.cpdLog.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              comment: action.payload.comment,
            }
          }

          return item
        }),
      }
    }

    case consts.FETCHING_STORE_HISTORY_LOG: {
      return {
        ...state,
        isFetchingStoreLog: action.payload,
      }
    }

    case consts.RECEIVE_STORE_HISTORY_LOG: {
      return {
        ...state,
        isFetchingStoreLog: false,
      }
    }

    default: {
      return state
    }
  }
}

export default historyReducer
