import React, { useEffect } from 'react'
import { Progress, Table, Tag, Tooltip, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Log } from './styled'
import { date } from '../../../../../helpers'
import { useDocumentTitle, useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'

const { Title } = Typography

const getTime = (time: number, webinarType?: string) => {
  if (time === 0 && webinarType && webinarType === 'LIVE') return `-`

  const hours = Math.floor(time / 60)
  // @ts-ignore
  const minutes = time % 60 ?? '00'

  if (!hours) {
    return `${minutes}m`
  }

  return `${hours}h ${minutes}m`
}

const AccountHistory = ({ getHistoryLog }: { getHistoryLog: (params?: any) => void }) => {
  const { isFetchingLogs, log } = useShallowEqualSelector((state: any) => state.history)

  const navigate = useNavigate()

  useEffect(() => {
    getHistoryLog()
  }, [])

  useDocumentTitle('Learning Hub History')

  // eslint-disable-next-line react/no-unstable-nested-components
  const HistoryLogIcon = ({ webinar, completion }: { webinar: any; completion: number }) => {
    const showResume = !((webinar.record_type === 'RECORDED' && completion >= 99) || webinar.record_type === 'LIVE')

    return (
      <>
        {showResume && (
          <Tooltip title='Resume video' key={webinar.id} color={theme.colors.gray[600]} mouseEnterDelay={0.5}>
            <Log.Action showResume={showResume} onClick={() => navigate(`/previous-webinars/${webinar.slug}`)} />
          </Tooltip>
        )}
        {!showResume && <Log.Action />}
      </>
    )
  }

  const columns = [
    {
      title: 'Webinar title',
      dataIndex: 'webinar',
      key: 'webinar',
      width: 350,
      render: (webinar: any, historyLog: any) => (
        <Log.Title>
          <HistoryLogIcon webinar={webinar} completion={+historyLog.completion} />
          {webinar.title}
        </Log.Title>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => {
        if (type === 'LIVE') {
          return <Tag color={theme.colors.red[200]}>{type}</Tag>
        }

        if (type === 'IN PERSON') {
          return <Tag color={theme.colors.lightBlue[200]}>{type}</Tag>
        }

        if (type === 'RECORDED') {
          return (
            <Tag color={theme.colors.yellow[200]} style={{ color: theme.colors.blue[200] }}>
              {type}
            </Tag>
          )
        }

        return null
      },
    },
    {
      title: 'View time',
      dataIndex: 'view_time',
      key: 'view_time',
      render: (viewTime: number, historyLog: any) => getTime(viewTime, historyLog.type),
    },
    {
      title: 'Duration',
      dataIndex: 'webinar_duration',
      key: 'webinar_duration',
      render: (duration: number) => getTime(duration),
    },
    {
      title: 'Completion %',
      dataIndex: 'completion',
      key: 'completion',
      render: (completion: number) => (
        <Progress percent={Math.round(completion)} strokeWidth={6} strokeColor={theme.colors.green[200]} />
      ),
    },
    {
      title: 'Completion date',
      dataIndex: 'completion_time',
      key: 'completion_time',
      render: (completionTime: string) => date.formatUTC(completionTime, 'dd/MM/yyyy'),
    },
  ]

  return (
    <Log>
      <Title level={3}>Learning Hub History</Title>

      <Table
        className='table-cpd-log'
        rowKey={(row) => row.id}
        loading={isFetchingLogs}
        dataSource={log}
        columns={columns}
        pagination={false}
        scroll={{ x: '90rem' }}
      />
    </Log>
  )
}
export default AccountHistory
