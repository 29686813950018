import React, { useEffect } from 'react'
import { Button, Col, Form, Grid, Input, Row, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Profile } from './styled'
import { regexp } from '../../../../../helpers'
import { useDocumentTitle, useShallowEqualSelector } from '../../../../../hooks'

const { useBreakpoint } = Grid
const { Title } = Typography

const AccountProfile = ({
  updateProfileDetails,
  updateProfilePassword,
}: {
  updateProfileDetails: (values: any) => void
  updateProfilePassword: (values: any) => void
}) => {
  const [form] = Form.useForm()
  const screens = useBreakpoint()
  const navigate = useNavigate()

  const { isFetchingUpdateDetails, isFetchingUpdatePassword, profileDetails } = useShallowEqualSelector(
    (state: any) => state.auth,
  )

  useEffect(() => {
    if (profileDetails.id) {
      form.setFieldsValue({
        ...profileDetails,
      })
    }
  }, [profileDetails.id])

  useDocumentTitle('My Profile')

  const onFinishDetails = (values: any) =>
    updateProfileDetails({ ...values, emailChanged: values.email !== profileDetails.email })

  const onFinishPassword = (values: any) =>
    updateProfilePassword({
      ...values,
      password_confirmation: values.password,
    })

  return (
    <Profile>
      <Row gutter={[32, 32]}>
        <Col xl={12} xs={24}>
          <Profile.Card>
            <Title level={3}>Your Details</Title>

            <Form form={form} onFinish={onFinishDetails} autoComplete='off' layout='vertical'>
              <Form.Item label='First name' name='first_name'>
                <Input placeholder='First name' />
              </Form.Item>

              <Form.Item label='Last name' name='last_name'>
                <Input placeholder='Last name' />
              </Form.Item>

              <Form.Item label='Job title' name='job_title'>
                <Input placeholder='Job title' />
              </Form.Item>

              <Form.Item label='Company' name='company'>
                <Input placeholder='Company name' />
              </Form.Item>

              <Form.Item label='Email address' name='email'>
                <Input placeholder='Enter address' />
              </Form.Item>

              <Form.Item
                label='Phone number'
                name='phone'
                rules={[
                  {
                    min: 9,
                    message: 'Phone number must be at least 9 digits',
                  },
                  {
                    max: 14,
                    message: 'Phone number must be no more than 14 digits',
                  },
                ]}
              >
                <Input type='number' placeholder='Phone number' />
              </Form.Item>

              <Profile.Submit>
                <Form.Item noStyle>
                  <Button type='primary' htmlType='submit' loading={isFetchingUpdateDetails} block={!screens.xl} ghost>
                    Save Changes
                  </Button>
                </Form.Item>
              </Profile.Submit>
            </Form>
          </Profile.Card>
        </Col>

        <Col xl={12} xs={24}>
          <Profile.Card>
            <Title level={3}>Change Password</Title>

            <Form onFinish={onFinishPassword} autoComplete='off' layout='vertical'>
              <Form.Item
                required={false}
                label='Current Password'
                name='old_password'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your current password',
                  },
                ]}
              >
                <Input.Password visibilityToggle={false} autoComplete='new-password' />
              </Form.Item>

              <Form.Item
                required={false}
                label='New Password'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your new password',
                  },
                  {
                    pattern: regexp.passwordPattern(),
                    min: 8,
                    message: 'At least 8 characters (lower and uppercase letters, numbers, special characters)',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Profile.Submit>
                <Form.Item noStyle>
                  <Button type='primary' htmlType='submit' loading={isFetchingUpdatePassword} block={!screens.xl} ghost>
                    Change Password
                  </Button>
                </Form.Item>
              </Profile.Submit>
            </Form>
          </Profile.Card>

          <Profile.Feedback>
            <Profile.Feedback.Inner>
              <Profile.Feedback.Title>Have any feedback?</Profile.Feedback.Title>

              <Profile.Feedback.Description>
                We hope the Estate Research Learning Hub proves a useful resource for Private Client Practitioners and
                would love to hear your ideas of how we can continuously improve.
              </Profile.Feedback.Description>

              <Profile.Feedback.Action>
                <Button type='primary' onClick={() => navigate('/feedback')} ghost>
                  Give Feedback
                </Button>
              </Profile.Feedback.Action>
            </Profile.Feedback.Inner>
          </Profile.Feedback>
        </Col>
      </Row>
    </Profile>
  )
}

export default AccountProfile
