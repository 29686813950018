import { message } from 'antd'
import { api } from '../../services'
import { feedback as consts } from '../constants'

const sendFeedback = (body: any, form: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_SEND_FEEDBACK,
      payload: true,
    })

    const { data } = await api.post('/feedback', body)

    form.resetFields()

    message.success('Feedback sent successfully')

    dispatch({
      type: consts.RECEIVE_SEND_FEEDBACK,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_SEND_FEEDBACK,
      payload: false,
    })
  }
}

// eslint-disable-next-line import/prefer-default-export
export { sendFeedback }
