import React, { useEffect } from 'react'
import { Typography, Button, Grid } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FeaturedSpeakers } from './styled'
import { useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'
import { LayoutCentering, CustomCarousel, Ellipse, SpeakerItem } from '../../../../components'

const { Title } = Typography
const { useBreakpoint } = Grid

const WebinarsFeaturedSpeakers = ({ getSpeakersList }: { getSpeakersList: any }) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const { featuredSpeakers } = useShallowEqualSelector((state: any) => state.speakers)

  useEffect(() => {
    getSpeakersList({ featured: true, per_page: 6 })
  }, [])

  return (
    <FeaturedSpeakers>
      <FeaturedSpeakers.SemiRing>
        <Ellipse type='semiringCircle' color={theme.colors.violet[200]} size={8} rotate={-145} />
      </FeaturedSpeakers.SemiRing>

      <FeaturedSpeakers.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </FeaturedSpeakers.SemiCircle>

      <LayoutCentering>
        <FeaturedSpeakers.Header>
          <Title level={2}>Featured Speakers</Title>

          {screens.lg && (
            <Button type='primary' onClick={() => navigate('/speakers')}>
              View all speakers
            </Button>
          )}
        </FeaturedSpeakers.Header>
      </LayoutCentering>

      <FeaturedSpeakers.List>
        {/* @ts-expect-error broken types */}
        <CustomCarousel slidesToShow={screens.lg ? 2 : 1} infinite variableWidth={screens.lg} centerMode={screens.lg}>
          {featuredSpeakers.list.map((item: any) => (
            <FeaturedSpeakers.Item key={item.id}>
              <SpeakerItem item={item} />
            </FeaturedSpeakers.Item>
          ))}
        </CustomCarousel>
      </FeaturedSpeakers.List>

      {!screens.lg && (
        <FeaturedSpeakers.More>
          <Button type='primary' onClick={() => navigate('/speakers')} block>
            View all speakers
          </Button>
        </FeaturedSpeakers.More>
      )}
    </FeaturedSpeakers>
  )
}

export default WebinarsFeaturedSpeakers
