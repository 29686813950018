import styled from 'styled-components'
import { media } from '../../../styles'

const NotFound = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12rem 1.6rem;
  position: relative;
  text-align: center;

  .ant-btn {
    margin-top: 3.2rem;
  }

  h2.ant-typography {
    ${media.lg`
      font-size: 2.8rem;
    `}
  }

  ${media.lg`
    padding-top: 8rem;
    padding-bottom: 8rem;
  `}
` as any

NotFound.SemiCircle = styled.div`
  position: absolute;
  top: 11.5rem;
  right: -2.8rem;

  ${media.lg`
    top: calc(50% + 4.8rem);
    right: -1.2rem;
  `}
`

NotFound.SemiRing = styled.div`
  position: absolute;
  left: -13rem;
  top: 12.7rem;

  ${media.lg`
    top: 50%;
    left: -2.8rem;
  `}
`

// eslint-disable-next-line import/prefer-default-export
export { NotFound }
