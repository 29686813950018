import { Typography } from 'antd'
import styled from 'styled-components'
import { images } from '../../../assets'
import { media } from '../../../styles'
import { BlogPostItem } from '../../components/BlogItemsList/styled'

const { Title } = Typography

const BlogPost = styled.div`
  background-color: #edf6fd;
  position: relative;
` as any

BlogPost.Header = styled.div`
  background-color: ${({ theme }) => theme.colors.blue[100]};
  padding-top: 10rem;
  padding-bottom: 3rem;

  ${media.xl`
    padding-top: 4rem;
  `}

  &,
  .ant-typography,
  ${BlogPostItem.Date} {
    color: #fff;
  }

  .ant-divider {
    border-top-color: #335480;
  }
`

BlogPost.CircleGreen = styled.div`
  position: absolute;
  top: 50%;
  left: -11rem;

  ${media.xl`
    display: none;
  `}
`

BlogPost.SemiRing = styled.div`
  position: absolute;
  right: -10rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

BlogPost.Inner = styled.div`
  padding: 10rem 0;
  position: relative;

  ${media.xl`
    padding: 4rem 0;
  `}
`

BlogPost.Title = styled(Title)`
  margin-bottom: 60px !important;

  ${media.xl`
    font-size: 2.4rem !important;
    margin-bottom: 24px !important;
  `}
`

BlogPost.Content = styled(Typography)`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 1.6rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.gray[600]};
  min-height: 200px;

  ${media.lg`
    margin-top: 2.4rem;
  `}

  img {
    max-width: 100%;
    height: auto;
  }

  & > p:first-child {
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.blue[200]};
  }

  & > *:last-child {
    clear: both;
  }

  blockquote {
    clear: both;
    border-left: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[400]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
    padding: 4rem 0 4rem 16rem;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-style: italic;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.gray[600]};
    background: url(${images.iconBlockQuoteBg}) no-repeat left 4rem;
    min-height: 160px;

    ${media.lg`
      padding: 3.3rem 0 3.3rem 10rem;
   `}

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  figure {
    margin: 0 0 2rem;

    figcaption {
      font-family: ${({ theme }) => theme.fontFamily.secondary};
      font-style: italic;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.4;
      color: #9c9c9c;
      margin-top: 1.2rem;
    }

    &[class='image'] {
      text-align: center;
    }
  }

  figure.image-style-align-left,
  img[style*='float:left'] {
    float: left;
    margin-right: 2rem;

    ${media.lg`
        float: none;
        margin-right: 0;
      `}
  }

  figure.image-style-align-right,
  img[style*='float:right'] {
    float: right;
    margin-left: 2rem;

    ${media.lg`
        float: none;
        margin-left: 0;
      `}
  }

  table {
    width: 100%;
    vertical-align: top;
    border-color: #dee2e6;

    tbody {
      vertical-align: inherit;

      tr {
        border-color: inherit;
        border-style: solid;
        border-width: 0;

        td {
          padding: 8px;
          background-color: transparent;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }

        td:first-child {
          padding-left: 0;
        }

        td:last-child {
          padding-right: 0;
        }
      }
    }
  }

  hr {
    clear: both;
    margin-bottom: 3rem;
    border-color: transparent;
  }
`

// eslint-disable-next-line import/prefer-default-export
export { BlogPost }
