import styled from 'styled-components'
import { media } from '../../../styles'

const StyledBlogPageContainer = styled.div`
  padding-top: 2rem;
  position: relative;
`

type BlogPageContainerComponent = typeof StyledBlogPageContainer & {
  Wrapper: typeof Wrapper
  CircleGreen: typeof CircleGreen
  SemiRing: typeof SemiRing
  SemiCircle: typeof SemiCircle
  List: typeof List
}

const Wrapper = styled.div`
  background: #edf6fd;
`

const CircleGreen = styled.div`
  position: absolute;
  top: 50%;
  left: -11rem;

  ${media.xl`
    display: none;
  `}
`

const SemiRing = styled.div`
  position: absolute;
  right: -10rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

const SemiCircle = styled.div`
  position: absolute;
  top: 25%;
  right: -4.4rem;

  ${media.xl`
    display: none;
  `}
`

const List = styled.div`
  margin-bottom: 2.4rem;
`

const BlogPageContainer = StyledBlogPageContainer as BlogPageContainerComponent

BlogPageContainer.Wrapper = Wrapper
BlogPageContainer.CircleGreen = CircleGreen
BlogPageContainer.SemiRing = SemiRing
BlogPageContainer.SemiCircle = SemiCircle
BlogPageContainer.List = List

// Do the same for FeedbackBox
const StyledFeedbackBox = styled.div`
  background: ${({ theme }) => theme.colors.gray[200]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grayBlue[200]};
  padding: 28px 24px;
`

type FeedbackBoxComponent = typeof StyledFeedbackBox & {
  Header: typeof FeedbackHeader
  Content: typeof FeedbackContent
}

const FeedbackHeader = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 20px;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
  }

  p {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.blue[200]};
    margin: 0;
  }
`

const FeedbackContent = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  margin: 24px 0;
`

const FeedbackBox = StyledFeedbackBox as FeedbackBoxComponent

FeedbackBox.Header = FeedbackHeader
FeedbackBox.Content = FeedbackContent

export { BlogPageContainer, FeedbackBox }
