import { feedback as consts } from '../constants'

const initialState = {
  isFetchingSendFeedback: false,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const feedbackReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case consts.FETCHING_SEND_FEEDBACK: {
      return {
        ...state,
        isFetchingSendFeedback: action.payload,
      }
    }

    case consts.RECEIVE_SEND_FEEDBACK: {
      return {
        ...state,
        isFetchingSendFeedback: false,
      }
    }

    default: {
      return state
    }
  }
}

export default feedbackReducer
