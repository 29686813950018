import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { Wrapper } from './styled'

const NotificationImportant = ({ content }: { content: string }) => (
  <Wrapper>
    <Space align='start'>
      <InfoCircleOutlined />

      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Space>
  </Wrapper>
)

export default NotificationImportant
