import { events as consts } from '../constants'

const initialState = {
  isFetchingEvents: false,
  events: {
    list: [],
    meta: {},
  },

  isFetchingEventsLocations: false,
  eventsLocations: [],
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const eventsReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case consts.FETCHING_EVENTS: {
      return {
        ...state,
        isFetchingEvents: action.payload,
      }
    }

    case consts.RECEIVE_EVENTS: {
      return {
        ...state,
        isFetchingEvents: false,
        events: action.payload,
      }
    }

    case consts.FETCHING_EVENTS_LOCATIONS: {
      return {
        ...state,
        isFetchingEventsLocations: action.payload,
      }
    }

    case consts.RECEIVE_EVENTS_LOCATIONS: {
      return {
        ...state,
        isFetchingEventsLocations: false,
        eventsLocations: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default eventsReducer
