import React, { useState } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import Lottie from 'react-lottie'
import { Media } from './styled'
import { animations } from '../../../../../assets'
import { LayoutCentering } from '../../../../components'

const MainMedia = ({
  videoId,
  details,
  style,
}: {
  videoId?: string
  details: React.ReactNode
  style?: React.CSSProperties
}) => {
  const [videoPaused, setVideoPaused] = useState(true)

  const handlePlayerPause = () => {
    setVideoPaused(!videoPaused)
  }

  return (
    <Media style={style}>
      <LayoutCentering>
        <Media.Inner>
          <Media.Details>{details}</Media.Details>

          {videoId && (
            <Media.Video>
              {videoPaused && <Media.Video.Play onClick={handlePlayerPause} />}
              <Media.Video.Overflow onClick={handlePlayerPause} />

              {/* @ts-expect-error broken */}
              <Vimeo
                className='main-media-iframe'
                video={videoId}
                paused={videoPaused}
                showTitle={false}
                showByline={false}
                controls={false}
              />

              <Media.Plants>
                {/* @ts-expect-error missing props */}
                <Lottie
                  width='100%'
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animations.plants,
                  }}
                />
              </Media.Plants>
            </Media.Video>
          )}
        </Media.Inner>
      </LayoutCentering>
    </Media>
  )
}

export default MainMedia
