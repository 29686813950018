import styled, { css } from 'styled-components'

const Shape = styled.div<{ rotate?: number; type: string; color: string; size: number }>`
  display: inline-block;
  transform: rotate(${({ rotate }) => (rotate ? `${rotate}deg` : 0)});
  position: relative;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${() => `${rotate}deg`});
    `}

  ${({ type }) =>
    type === 'circle' &&
    css`
      width: ${({ size }: any) => (size ? `${size}rem` : '12rem')};
      height: ${({ size }: any) => (size ? `${size}rem` : '12rem')};
      background: ${({ theme, color }: any) => color || theme.colors.gray[700]};
      border-radius: 50%;
    `}

    ${({ type }) =>
    type === 'pacman' &&
    css`
      width: ${({ size }: any) => (size ? `${size}rem` : '12rem')};
      height: ${({ size }: any) => (size ? `${size}rem` : '12rem')};
      background: ${({ theme, color }: any) => color || theme.colors.gray[700]};
      border-radius: 50%;

      &:before {
        content: '';
        width: ${({ size }: any) => (size ? `${size / 2}rem` : '6rem')};
        height: ${({ size }: any) => (size ? `${size / 2}rem` : '6rem')};
        background: ${({ theme }: any) => theme.colors.gray[100]};
        position: absolute;
        left: 0;
        bottom: 0;
      }
    `}

  ${({ type }) =>
    type === 'semicircle' &&
    css`
      width: ${({ size }: any) => (size ? `${size}rem` : '12rem')};
      height: ${({ size }: any) => (size ? `${size / 2}rem` : '6rem')};
      border-radius: ${({ size }: any) => (size ? `${size * 2}rem` : '24rem')}
        ${({ size }: any) => (size ? `${size * 2}rem` : '24rem')} 0 0;
      background: ${({ theme, color }: any) => color || theme.colors.gray[700]};
    `}

  ${({ type }) =>
    type === 'semiring' &&
    css`
      width: ${({ size }: any) => (size ? `${size}rem` : '12rem')};
      height: ${({ size }: any) => (size ? `${size / 2}rem` : '6rem')};
      background-color: transparent;
      border-top-left-radius: ${({ size }: any) => (size ? `${size / 2 + size / 5}rem` : '8rem')};
      border-top-right-radius: ${({ size }: any) => (size ? `${size / 2 + size / 5}rem` : '8rem')};
      border: ${({ size }: any) => (size ? `${size / 4.2}rem` : '6rem')} solid
        ${({ theme, color }: any) => color || theme.colors.gray[700]};
      border-bottom: 0;
    `}

  ${({ type }) =>
    type === 'semiringCircle' &&
    css`
      width: ${({ size }: any) => (size ? `${size}rem` : '12rem')};
      height: ${({ size }: any) => (size ? `${size / 2}rem` : '6rem')};
      background-color: transparent;
      border-top-left-radius: ${({ size }: any) => (size ? `${size / 2 + size / 5}rem` : '8rem')};
      border-top-right-radius: ${({ size }: any) => (size ? `${size / 2 + size / 5}rem` : '8rem')};
      border: ${({ size }: any) => (size ? `${size / 3.2}rem` : '6rem')} solid
        ${({ theme, color }: any) => color || theme.colors.gray[700]};
      border-bottom: 0;

      &:before {
        content: '';
        width: ${({ size }: any) => (size ? `${size / 2.8 + 0.2}rem` : '6rem')};
        height: ${({ size }: any) => (size ? `${size / 2.8 + 0.2}rem` : '6rem')};
        background: ${({ theme }: any) => theme.colors.green[200]};
        border-radius: 50%;
        position: absolute;
      }
    `}
`

// eslint-disable-next-line import/prefer-default-export
export { Shape }
