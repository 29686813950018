import { List as ListLibrary, Card } from 'antd'
import { rgba } from 'polished'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Item = styled(ListLibrary.Item)`
  h4.ant-typography {
    height: 11.2rem;
    font-size: 2rem;
    margin: 2.4rem 0;
  }
` as any

Item.Inner = styled(Card)`
  width: 100%;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
  }
`

Item.Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

Item.Header.Name = styled.div`
  display: flex;
  flex-direction: column;
`

Item.Header.Company = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  padding-top: 4px;
  text-transform: uppercase;
`

Item.Link = styled(Link)`
  color: ${({ theme }) => theme.colors.blue[200]} !important;
`

Item.Info = styled.div`
  margin-bottom: 1.8rem;
`

Item.Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  }
`

Item.Details.Name = styled.div`
  padding-left: 2.4rem;
  position: relative;
`

Item.Details.Icon = styled.div<{ src: string }>`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 0.2rem;
  transform: translateY(-50%);
`

Item.Details.Label = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  text-transform: uppercase;
`

Item.Details.Value = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
`

Item.Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4rem;
  gap: 1.6rem;

  .ant-btn {
    &[disabled] {
      flex: 1;
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { Item }
