import React, { useEffect } from 'react'
import { Typography } from 'antd'
import { Categories } from './styled'
import { useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'
import { LayoutCentering, WebinarCategoriesList, Ellipse } from '../../../../components'

const { Title } = Typography

const MainCategories = ({ getCategoriesList }: { getCategoriesList: any }) => {
  const { list } = useShallowEqualSelector((state: any) => state.categories)

  useEffect(() => {
    getCategoriesList({})
  }, [])

  return (
    <Categories>
      <Categories.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </Categories.SemiRing>

      <Categories.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </Categories.SemiCircle>

      <LayoutCentering>
        <Categories.Header>
          <Title level={2}>Webinar Categories</Title>
        </Categories.Header>

        <WebinarCategoriesList dataSource={list} />
      </LayoutCentering>
    </Categories>
  )
}

export default MainCategories
