import React from 'react'
import { Layout } from './styled'

const LayoutCentering = ({
  children,
  relative,
  maxWidth,
}: {
  children: React.ReactNode
  relative?: boolean
  maxWidth?: string
}) => (
  <Layout relative={relative} $maxWidth={maxWidth}>
    {children}
  </Layout>
)

export default LayoutCentering
