import styled from 'styled-components'
import { media } from '../../../styles'

const Webinars = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  padding-top: 10rem;
  position: relative;

  ${media.xl`
    padding-top: 4rem;
  `}
` as any

Webinars.CircleGreen = styled.div`
  position: absolute;
  top: 50%;
  left: -11rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.SemiRing = styled.div`
  position: absolute;
  right: -10rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.SemiCircle = styled.div`
  position: absolute;
  top: 25%;
  right: -4.4rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;

  h2.ant-typography {
    ${media.xl`
      font-size: 3.2rem;
    `}
  }

  ${media.xl`
    margin-bottom: 0.8rem;
  `}
`

Webinars.List = styled.div`
  margin-bottom: 2.4rem;
`

// eslint-disable-next-line import/prefer-default-export
export { Webinars }
