import { List as ListLibrary } from 'antd'
import styled from 'styled-components'
import { media } from '../../../styles'

const List = styled(ListLibrary)`
  position: relative;
  z-index: 1;

  ${media.lg`
    row-gap: 2.8rem
  `}
`
// eslint-disable-next-line import/prefer-default-export
export { List }
