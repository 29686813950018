import { List as ListLibrary, Card } from 'antd'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { images } from '../../../assets'

const Item = styled(ListLibrary.Item)<{ nostyle: boolean }>`
  &:last-child {
    margin-bottom: 0 !important;
  }

  ${({ nostyle }) =>
    nostyle &&
    css`
      ${Item.Inner} {
        border: 0;
        box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
      }
    `}
` as any

Item.Inner = styled(Card)`
  width: 100%;
  transition: 0.3s ease;

  &:hover {
    box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
  }
`

Item.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

Item.Region = styled.div`
  padding-left: 2.4rem;
  position: relative;
`

Item.Region.Icon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${images.iconRegion}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 0.2rem;
  transform: translateY(-50%);
`

Item.Region.Value = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  text-transform: uppercase;
`

Item.Body = styled.div<{ bottomLine: boolean }>`
  border-bottom: ${({ bottomLine }) => (bottomLine ? 'none' : '0.1rem solid')};
  border-color: ${({ theme, bottomLine }) => (bottomLine ? 'none' : theme.colors.gray[400])};
  padding-bottom: ${({ bottomLine }) => (bottomLine ? 0 : '0.8rem')};
  margin-bottom: ${({ bottomLine }) => (bottomLine ? 0 : '2.4rem')};
  height: 7.2rem;

  h4.ant-typography {
    width: 100%;
    font-size: 2rem;
    margin: 2rem 0 0.4rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

Item.Body.Company = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  margin-bottom: 1.6rem;
  text-transform: uppercase;
`

Item.Description = styled.div`
  min-height: 13.2rem;
  margin-bottom: 2.8rem;

  & > div {
    font-family: ${({ theme }) => theme.fontFamily.secondary};
  }
`

Item.Line = styled.div`
  width: 100% !important;
  margin-top: 2.4rem;
  position: absolute;
  left: 0;
  height: 0.1rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
`

Item.Info = styled.div`
  margin-top: 2.4rem;
`

Item.Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  }

  .ant-badge {
    margin-left: 0.8rem;

    &-count {
      background: ${({ theme }) => theme.colors.gray[400]};
      color: ${({ theme }) => theme.colors.gray[600]};
      font-weight: 600;
    }
  }
`

Item.Details.Name = styled.div`
  padding-left: 2.4rem;
  position: relative;
`

Item.Details.Icon = styled.div<{ src: string }>`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 0.2rem;
  transform: translateY(-50%);
`

Item.Details.Label = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  text-transform: uppercase;
`

Item.Details.Value = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
`

Item.Details.Value = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`

Item.Details.Truncate = styled.div`
  max-width: 11rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// eslint-disable-next-line import/prefer-default-export
export { Item }
