import styled from 'styled-components'

const ModalTitle = styled.span`
  margin-right: 7rem;
  font-weight: 600;
`

const Training = styled.div`` as any

Training.Submit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export { Training, ModalTitle }
