import React, { useEffect } from 'react'
import { Typography, Grid } from 'antd'
import { Watching } from './styled'
import { useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'
import { LayoutCentering, CustomCarousel, Ellipse, WebinarsContinueWatchingItem } from '../../../../components'

const { Title } = Typography
const { useBreakpoint } = Grid

const WebinarsContinueWatching = ({ getWebinarsInProgress }: { getWebinarsInProgress: any }) => {
  const screens = useBreakpoint()

  const { inProgressWebinars } = useShallowEqualSelector((state: any) => state.webinars)

  useEffect(() => {
    getWebinarsInProgress({})
  }, [])

  const numberWebinarsShown = 3

  if (inProgressWebinars.list.length === 0) {
    return null
  }

  return (
    <Watching>
      <Watching.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </Watching.SemiRing>

      <Watching.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </Watching.SemiCircle>

      <LayoutCentering>
        <Watching.Header>
          <Title level={2}>Continue Watching</Title>
        </Watching.Header>

        <Watching.List>
          <CustomCarousel
            // @ts-expect-error broken types
            slidesToShow={screens.lg ? numberWebinarsShown : 1}
            infinite={inProgressWebinars.list.length >= 3 || !(screens.lg && inProgressWebinars.list.length <= 3)}
            hideArrows={
              (screens.lg && inProgressWebinars.list.length <= numberWebinarsShown) ||
              inProgressWebinars.list.length <= 1
            }
          >
            {inProgressWebinars.list.map((item: any) => (
              <Watching.Item key={item.id}>
                <WebinarsContinueWatchingItem item={item} />
              </Watching.Item>
            ))}
          </CustomCarousel>
        </Watching.List>
      </LayoutCentering>
    </Watching>
  )
}

export default WebinarsContinueWatching
