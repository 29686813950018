import styled from 'styled-components'
import { media } from '../../../../../styles'

const UpcomingWebinars = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  padding: 10rem 0;
  position: relative;

  ${media.lg`
    padding: 3.2rem 0;
  `}
` as any

UpcomingWebinars.CircleGreen = styled.div`
  position: absolute;
  bottom: 16.6rem;
  left: -11rem;

  ${media.lg`
    display: none;
  `}
`

UpcomingWebinars.CircleYellow = styled.div`
  position: absolute;
  top: 13.5rem;
  right: -3.6rem;

  ${media.lg`
    top: 3.5rem;
    right: -6.4rem;
  `}
`

UpcomingWebinars.SemiRing = styled.div`
  position: absolute;
  left: -10rem;
  top: 35rem;

  ${media.lg`
    display: none;
  `}
`

UpcomingWebinars.SemiCircle = styled.div`
  position: absolute;
  right: -4.4rem;
  bottom: 17.6rem;
`

UpcomingWebinars.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6.4rem;
  position: relative;
  z-index: 1;

  h2.ant-typography {
    ${media.lg`
      font-size: 3.2rem;
    `}
  }

  ${media.lg`
    margin-bottom: 1.6rem;
  `}
`

UpcomingWebinars.List = styled.div`
  ${media.lg`
    margin-right: -1.6rem;
  `}
`

UpcomingWebinars.Item = styled.div`
  ${media.lg`
    padding-right: 1.6rem;
  `}
`

UpcomingWebinars.More = styled.div`
  margin-top: 2.4rem;
  padding: 0 1.6rem 0 0;
`

// eslint-disable-next-line import/prefer-default-export
export { UpcomingWebinars }
