import { Layout as LayoutLibrary } from 'antd'
import styled from 'styled-components'
import { images } from '../../../assets'
import { media } from '../../../styles'

const Layout = styled(LayoutLibrary)`
  min-height: 100vh;
  overflow: hidden;

  ${media.lg`
      min-height: auto;
    `}
` as any

Layout.Content = styled(LayoutLibrary.Content)`
  display: flex;
  align-items: stretch;
  padding-left: 52rem;
  position: relative;

  &:before {
    content: '';
    width: 34rem;
    height: 34rem;
    background: url(${images.pictureKeyCircle}) no-repeat center / contain;
    position: fixed;
    right: -4rem;
    bottom: 0;

    ${media.lg`
      width: 17rem;
      height: 17rem;
    `}
  }

  ${media.lg`
    padding-left: 0;
  `}
`

const FixedBar = styled.div`
  width: 52rem;
  height: 100%;
  background: ${({ theme }) => theme.colors.blue[200]} url(${images.patternKeyCircle}) no-repeat bottom / contain;
  padding: 3.2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, ${({ theme }) => theme.colors.blue[200]} 50%, rgba(19, 43, 75, 0.4) 170%);
    position: absolute;
    bottom: 0;
    left: 0;

    ${media.lg`
      height: 100%;
    `}
  }

  ${media.lg`
    width: 100%;
    height: 8.8rem;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 0 1.6rem;
  `}
` as any

FixedBar.Circle = styled.div`
  position: absolute;
  left: -6rem;
  bottom: -6rem;

  ${media.lg`
    top: -2.8rem;
    right: 2.4rem;
    left: initial;
    bottom: initial
  `}
`

FixedBar.SemiCircle = styled.div`
  position: absolute;
  left: -3rem;
  top: 50%;

  ${media.lg`
    display: none;
  `}
`

FixedBar.SemiRing = styled.div`
  position: absolute;
  right: -6rem;
  bottom: 16rem;

  ${media.lg`
    bottom: -1.8rem;
    right: -2.8rem;
  `}
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 6.4rem 3.2rem 3.2rem 3.2rem;
  position: relative;

  ${media.lg`
    padding: 15.4rem 1.6rem 3.2rem 1.6rem;
  `}

  ${media.sm`
    flex-direction: column;
    justify-content: flex-start;
    padding: 17rem 1.6rem 3.2rem 1.6rem;
  `}
`

const Logotype = styled.img`
  width: 23.6rem;
  position: relative;
  z-index: 1;
`

export { Layout, FixedBar, Wrapper, Logotype }
