import { message } from 'antd'
import { api } from '../../services'
import { forms as consts } from '../constants'

const referACase = (body: any, form: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_REFER_A_CASE,
      payload: true,
    })

    const { data } = await api.post('/forms/refer-a-case', body)

    form.resetFields()

    message.success('Enquiry sent successfully')

    dispatch({
      type: consts.RECEIVE_REFER_A_CASE,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_REFER_A_CASE,
      payload: false,
    })
  }
}

// eslint-disable-next-line import/prefer-default-export
export { referACase }
