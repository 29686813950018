import { List, Typography } from 'antd'
import styled from 'styled-components'
import { media } from '../../../styles'

export const StyledContent = styled(Typography)`
  a {
    font-weight: 700;
    text-decoration: underline;
  }
`

const StyledEvents = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  padding-top: 10rem;
  position: relative;

  ${media.xl`
    padding-top: 4rem;
  `}
` as any

StyledEvents.CircleGreen = styled.div`
  position: absolute;
  top: 50%;
  left: -11rem;

  ${media.xl`
    display: none;
  `}
`

StyledEvents.SemiRing = styled.div`
  position: absolute;
  right: -10rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

StyledEvents.SemiCircle = styled.div`
  position: absolute;
  top: 25%;
  right: -4.4rem;

  ${media.xl`
    display: none;
  `}
`

StyledEvents.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;

  h2.ant-typography {
    ${media.xl`
      font-size: 3.2rem;
    `}
  }

  ${media.xl`
    margin-bottom: 0.8rem;
  `}
`

StyledEvents.List = styled(List)`
  margin-top: 40px;
  margin-bottom: 2.4rem;

  ${media.xl`
    margin-top: 40px;
    margin-bottom: 2.4rem;
  `}

  .ant-col {
    height: 100%;
  }
`

export { StyledEvents }
