import queryString from 'query-string'
import { api } from '../../services'
import { events as consts } from '../constants'

const getEvents = (query: any) => async (dispatch: any) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_EVENTS,
      payload: true,
    })

    const { data } = await api.get(`/events?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_EVENTS,
      payload: {
        list: data.data,
        meta: data.meta,
      },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_EVENTS,
      payload: false,
    })
  }
}

const getEventsLocations = (query: any) => async (dispatch: any) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_EVENTS_LOCATIONS,
      payload: true,
    })

    const { data } = await api.get(`/events/locations?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_EVENTS_LOCATIONS,
      payload: data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_EVENTS_LOCATIONS,
      payload: false,
    })
  }
}

export { getEvents, getEventsLocations }
