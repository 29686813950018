import styled from 'styled-components'

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 6.4rem 0;
`

// eslint-disable-next-line import/prefer-default-export
export { Layout }
