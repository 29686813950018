import styled from 'styled-components'
import { media } from '../../../styles'

const Message = styled.div`
  max-width: 49rem;
  text-align: center;

  h2.ant-typography {
    margin: 4rem 0;

    ${media.lg`
      font-size: 2.8rem;
      margin: 2.4rem 0;
    `}
  }
` as any

Message.Picture = styled.img`
  height: 14rem;

  ${media.lg`
    height: 10rem;
  `}
`

// eslint-disable-next-line import/prefer-default-export
export { Message }
