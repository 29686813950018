/* eslint-disable import/prefer-default-export */

export const formatMoney = (amount: number) => {
  const options = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  } satisfies Intl.NumberFormatOptions
  if (amount % 100 === 0) {
    options.minimumFractionDigits = 0
  }
  const formatter = Intl.NumberFormat('en-GB', options)
  return formatter.format(amount / 100)
}
