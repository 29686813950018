import styled from 'styled-components'

const Filter = styled.div`
  h5.ant-typography {
    font-size: 2rem;
  }

  .ant-collapse {
    &.ant-collapse-ghost {
      .ant-collapse-item {
        &:not(:last-child) {
          border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
        }

        &-active {
          .ant-collapse-header {
            .ant-collapse-arrow {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .ant-collapse-header {
        color: ${({ theme }) => theme.colors.gray[600]};
        font-weight: 600;
        padding: 2rem 0;

        .ant-collapse-arrow {
          transform: rotate(90deg);
          top: calc(50% - 0.5rem);
          right: 0;
        }
      }

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }

  .ant-checkbox-group {
    &-item {
      width: 100%;
      margin-bottom: 0.8rem;

      .ant-checkbox {
        & + span {
          width: 100%;
          padding-right: 0;
        }
      }
    }
  }
` as any

Filter.Title = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

Filter.Mobile = styled.div`
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`
Filter.Item = styled.div`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  padding-right: 1rem;
`

Filter.Icon = styled.div<{ src: string }>`
  width: 2.4rem;
  height: 2.4rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
`

Filter.ExpandButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.red[200]};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.red[300]};
    text-decoration: underline;
  }
`
// eslint-disable-next-line import/prefer-default-export
export { Filter }
