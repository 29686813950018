import React from 'react'
import { Typography, Button, Avatar } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { Item } from './styled'
import { images } from '../../../assets'
import { date } from '../../../helpers'
import { theme } from '../../../styles'

const { Title, Text } = Typography

const WebinarFeaturedItem = ({ item }: { item: any }) => {
  const navigate = useNavigate()

  return (
    <Item>
      <Item.Inner>
        <Item.Header>
          <Title level={4} ellipsis={{ rows: 4 }}>
            <Link to={`/previous-webinars/${item.slug}`}>{item.title}</Link>
          </Title>

          <Item.Header.Speakers>
            <Avatar.Group
              maxCount={3}
              size={52}
              maxStyle={{
                backgroundColor: theme.colors.lightBlue[200],
                fontSize: '1.4rem',
                fontWeight: 600,
              }}
            >
              {item.speakers.map((speaker: any) => (
                <Avatar key={speaker.image} src={speaker.image} />
              ))}
            </Avatar.Group>

            {item.speakers.length > 0 && (
              <Item.Header.Name>
                <Text>{item.speakers.length > 1 ? 'Multiple Speakers' : `${item.speakers[0].name}`}</Text>
                <Item.Header.Company>{item.speakers[0].company}</Item.Header.Company>
              </Item.Header.Name>
            )}
          </Item.Header.Speakers>
        </Item.Header>

        <Item.Info>
          <Item.Info.Inner>
            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconDate} />
                <Item.Details.Label>Webinar date</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>{date.formatUTC(item.start_time)}</Item.Details.Value>
            </Item.Details>

            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconRegion} />
                <Item.Details.Label>Region</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>{item.region.title}</Item.Details.Value>
            </Item.Details>
          </Item.Info.Inner>

          <Item.Watch>
            <Button type='primary' onClick={() => navigate(`/previous-webinars/${item.slug}`)} ghost>
              Watch now <Item.Watch.Icon src={images.iconPlay} />
            </Button>
          </Item.Watch>
        </Item.Info>
      </Item.Inner>
    </Item>
  )
}

export default WebinarFeaturedItem
