import styled from 'styled-components'
import { media } from '../../../styles'

const PrivacyPolicy = styled.div`
  padding: 10rem 0;
  position: relative;

  ${media.lg`
    padding: 4rem 0;
  `}
` as any

PrivacyPolicy.SemiCircleBlue = styled.div`
  position: absolute;
  left: 4rem;
  top: 27rem;

  ${media.xl`
    display: none;
  `}
`

PrivacyPolicy.SemiRingYellow = styled.div`
  position: absolute;
  right: -9.6rem;
  top: 20rem;

  ${media.xl`
    display: none;
  `}
`

PrivacyPolicy.SemiRingRed = styled.div`
  position: absolute;
  top: 30%;
  left: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

PrivacyPolicy.SemiRingViolet = styled.div`
  position: absolute;
  top: 45%;
  right: 12rem;

  ${media.xl`
    display: none;
  `}
`

PrivacyPolicy.CircleYellow = styled.div`
  position: absolute;
  left: -3.6rem;
  top: 67%;

  ${media.xl`
    display: none;
  `}
`

PrivacyPolicy.PacmanBlue = styled.div`
  position: absolute;
  right: 3.2rem;
  top: 85%;

  ${media.xl`
    display: none;
  `}
`

PrivacyPolicy.Inner = styled.div`
  width: 100%;
  max-width: 75.2rem;
  margin: 0 auto;
  padding: 0 1.6rem;
  font-family: ${({ theme }) => theme.fontFamily.secondary};

  div.ant-typography {
    line-height: 2rem;
    margin-bottom: 2.4rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ theme }) => theme.fontFamily.primary};
  }

  h2.ant-typography {
    ${media.lg`
      font-size: 2.8rem;
    `}
  }

  ul {
    padding-left: 2rem;
    margin-bottom: 2.4rem;

    li {
      margin: 0.8rem 0;
    }
  }

  ol {
    list-style: none;
    counter-reset: counter;
    padding-left: 0;

    li {
      counter-increment: counter;
      padding-left: 3.6rem;
      margin: 0.8rem 0;
      position: relative;

      &:before {
        content: counter(counter);
        width: 2.4rem;
        height: 2.4rem;
        line-height: 2.4rem;
        background-color: ${({ theme }) => theme.colors.yellow[200]};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 600;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  h5.ant-typography {
    margin: 4rem 0 2.4rem 0;
  }
`

PrivacyPolicy.Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.blue[200]};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
`

// eslint-disable-next-line import/prefer-default-export
export { PrivacyPolicy }
