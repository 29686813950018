import React, { useCallback } from 'react'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import queryString from 'query-string'
import { Pagination } from './styled'

const getSearchParams = () => queryString.parse(globalThis.location.search, { arrayFormat: 'bracket' })

const PaginationDots = ({
  onRequest,
  meta,
  isScrollToTop = false,
  onScroll = window.scroll,
  customParams = {},
}: {
  onRequest: (params: any) => void
  meta: any
  isScrollToTop?: boolean
  onScroll?: (params: any) => void
  customParams?: any
}) => {
  const navigateTopHandler = useCallback(() => {
    onScroll({ top: 0, behavior: 'smooth' })
  }, [onScroll])

  const handlePrevPage = () => {
    const searchParams = getSearchParams()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isScrollToTop && navigateTopHandler()
    onRequest({ ...searchParams, page: meta.current_page === 1 ? 1 : meta.current_page - 1, ...customParams })
  }

  const handleNextPage = () => {
    const searchParams = getSearchParams()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isScrollToTop && navigateTopHandler()

    onRequest({
      ...searchParams,
      page: meta.current_page === meta.last_page ? meta.last_page : meta.current_page + 1,
      ...customParams,
    })
  }

  const handleToPage = (key: number) => {
    const searchParams = getSearchParams()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isScrollToTop && navigateTopHandler()
    onRequest({ ...searchParams, page: key + 1, ...customParams })
  }

  if (meta.last_page === 1) {
    return null
  }

  return (
    <Pagination>
      <Pagination.Prev onClick={handlePrevPage} blocked={meta.current_page === 1}>
        <ArrowLeftOutlined />
      </Pagination.Prev>

      <Pagination.Dots>
        {/* eslint-disable-next-line unicorn/no-new-array */}
        {[...new Array(meta.last_page).keys()].map((key) => (
          <Pagination.Dot key={key} active={meta.current_page === key + 1} onClick={() => handleToPage(key)} />
        ))}
      </Pagination.Dots>

      <Pagination.Next onClick={handleNextPage} blocked={meta.current_page === meta.last_page}>
        <ArrowRightOutlined />
      </Pagination.Next>
    </Pagination>
  )
}

export default PaginationDots
