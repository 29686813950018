import { message } from 'antd'
import queryString from 'query-string'
import { api } from '../../services'
import { history as consts } from '../constants'

const getHistoryCPDLogPdf = (query: any) => async (dispatch: any) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })
  try {
    dispatch({
      type: consts.FETCHING_HISTORY_CPD_LOG_PDF,
      payload: true,
    })

    const response = await api.get(`/history-pdf?${queryParams}`, {
      responseType: 'blob',
    })

    const url = globalThis.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', 'CPD-Log.pdf')
    document.body.append(link)
    link.click()

    URL.revokeObjectURL(link.href)

    dispatch({
      type: consts.FETCHING_HISTORY_CPD_LOG_PDF,
      payload: false,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_HISTORY_CPD_LOG_PDF,
      payload: false,
    })
  }
}

const getHistoryLog = () => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_HISTORY_LOG,
      payload: true,
    })

    const { data } = await api.get('/history')

    dispatch({
      type: consts.RECEIVE_HISTORY_LOG,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_HISTORY_LOG,
      payload: false,
    })
  }
}

const getHistoryCPDLog = (query: any) => async (dispatch: any) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_HISTORY_CPD_LOG,
      payload: true,
    })

    const { data } = await api.get(`/cpd-history?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_HISTORY_CPD_LOG,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_HISTORY_CPD_LOG,
      payload: false,
    })
  }
}

const createCPDLog = (body: any, onCancel: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_CREATE_CPD_LOG,
      payload: true,
    })

    const { data } = await api.post('/custom-history', body)

    onCancel()

    dispatch({
      type: consts.RECEIVE_CREATE_CPD_LOG,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_CREATE_CPD_LOG,
      payload: false,
    })
  }
}

const removeCPDLog = (id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_REMOVE_CPD_LOG,
      payload: true,
    })

    await api.delete(`/history/${id}`)

    dispatch({
      type: consts.RECEIVE_REMOVE_CPD_LOG,
      payload: { id },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_REMOVE_CPD_LOG,
      payload: false,
    })
  }
}

const updateCommentCPDLog = (id: string, body: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_UPDATE_COMMENT_CPD_LOG,
      payload: true,
    })

    await api.post(`/history-comment/${id}`, body)

    message.success('Comment updated successfully')

    dispatch({
      type: consts.RECEIVE_UPDATE_COMMENT_CPD_LOG,
      payload: { id, ...body },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_UPDATE_COMMENT_CPD_LOG,
      payload: false,
    })
  }
}

const storeHistoryLog = (body: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: consts.FETCHING_STORE_HISTORY_LOG,
      payload: true,
    })

    await api.post('/history', body)

    dispatch({
      type: consts.RECEIVE_STORE_HISTORY_LOG,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_STORE_HISTORY_LOG,
      payload: false,
    })
  }
}

export {
  getHistoryLog,
  getHistoryCPDLog,
  createCPDLog,
  removeCPDLog,
  updateCommentCPDLog,
  storeHistoryLog,
  getHistoryCPDLogPdf,
}
