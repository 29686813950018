import React from 'react'
import { RightOutlined, CheckOutlined } from '@ant-design/icons'
import { Typography, Button, Avatar } from 'antd'
import { Item } from './styled'
import { images } from '../../../assets'
import { date } from '../../../helpers'
import { theme } from '../../../styles'

const { Title, Text } = Typography

const WebinarUpcomingItem = ({
  registerToWebinar,
  cancelWebinarRegistration,
  item,
}: {
  registerToWebinar: (id: string) => void
  cancelWebinarRegistration: (id: string) => void
  item?: any
}) => (
  <Item>
    <Item.Inner>
      <Item.Header>
        <Avatar.Group
          maxCount={3}
          size={52}
          maxStyle={{
            backgroundColor: theme.colors.lightBlue[200],
            fontSize: '1.4rem',
            fontWeight: 600,
          }}
        >
          {item.speakers.map((speaker: any) => (
            <Avatar key={speaker.image} src={speaker.image} />
          ))}
        </Avatar.Group>

        {item.speakers.length > 0 && (
          <Item.Header.Name>
            <Text strong>
              {item.speakers.length > 1 ? (
                'Multiple Speakers'
              ) : (
                <Item.Link to={`/speakers/${item.speakers[0].slug}`}>{item.speakers[0].name}</Item.Link>
              )}
            </Text>
            <Item.Header.Company>{item.speakers[0].company}</Item.Header.Company>
          </Item.Header.Name>
        )}
      </Item.Header>

      <Title level={4} ellipsis={{ rows: 4 }}>
        <Item.Link to='/upcoming-webinars'>{item.title}</Item.Link>
      </Title>

      <Item.Info>
        <Item.Details>
          <Item.Details.Name>
            <Item.Details.Icon src={images.iconDate} />
            <Item.Details.Label>Webinar date</Item.Details.Label>
          </Item.Details.Name>

          <Item.Details.Value>{date.formatUTC(item.start_time)}</Item.Details.Value>
        </Item.Details>

        <Item.Details>
          <Item.Details.Name>
            <Item.Details.Icon src={images.iconClock} />
            <Item.Details.Label>Webinar length</Item.Details.Label>
          </Item.Details.Name>

          <Item.Details.Value>{item.duration} minutes</Item.Details.Value>
        </Item.Details>

        <Item.Details>
          <Item.Details.Name>
            <Item.Details.Icon src={images.iconRegion} />
            <Item.Details.Label>Region</Item.Details.Label>
          </Item.Details.Name>

          <Item.Details.Value>{item.region.title}</Item.Details.Value>
        </Item.Details>
      </Item.Info>

      {item.register_status === 0 && (
        <Item.Actions>
          <Button type='primary' onClick={() => registerToWebinar(item.id)} ghost block>
            Register <RightOutlined />
          </Button>
        </Item.Actions>
      )}
      {item.register_status === 1 && (
        <Item.Actions>
          <Button type='primary' loading disabled>
            Register <RightOutlined />
          </Button>
        </Item.Actions>
      )}
      {item.register_status === 2 && (
        <Item.Actions>
          <Button type='primary' onClick={() => cancelWebinarRegistration(item.id)} ghost>
            Cancel
          </Button>

          <Button type='primary' disabled>
            Registered <CheckOutlined />
          </Button>
        </Item.Actions>
      )}
    </Item.Inner>
  </Item>
)

export default WebinarUpcomingItem
