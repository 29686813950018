import React from 'react'
import { ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons'
import { Button, Row, Col, Grid, Divider } from 'antd'
import { BlogPostItem, List } from './styled'
import { formatUTC } from '../../../helpers/date'

const { useBreakpoint } = Grid

const BlogItemsList = ({ loading, dataSource }: { loading: boolean; dataSource: any }) => {
  const screens = useBreakpoint()
  const isMobile = screens.xs

  return (
    <List
      loading={loading}
      dataSource={dataSource}
      renderItem={(item: any) => (
        <BlogPostItem href={`/monthly-digest/${item.slug}`} target='_blank' rel='noreferrer noopener'>
          <BlogPostItem.Date>
            <CalendarOutlined /> {formatUTC(item.created_at, 'dd/MM/yyyy')}
          </BlogPostItem.Date>
          <BlogPostItem.Title>{item.title}</BlogPostItem.Title>
          <BlogPostItem.Excerpt>{item.excerpt}</BlogPostItem.Excerpt>
          <Divider />
          <Row align='middle' wrap={isMobile}>
            <Col xs={isMobile ? 24 : undefined} flex={isMobile ? undefined : 'auto'} />
            <Col xs={isMobile ? 24 : undefined} style={{ marginTop: isMobile ? 16 : 0 }}>
              <Button type='primary' ghost block={isMobile}>
                Open <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </BlogPostItem>
      )}
    />
  )
}

export default BlogItemsList
