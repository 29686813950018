import Vimeo from '@u-wave/react-vimeo'
import styled from 'styled-components'
import { media } from '../../../../../styles'

const ModalTitle = styled.span`
  margin-right: 4rem;
`

// @ts-expect-error broken
const Media = styled(Vimeo)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .main-media-iframe {
    iframe {
      width: 62.4rem;
      height: 36rem;

      ${media.lg`
        width: 34rem;
        height: 20rem;
      `}

      ${media.xs`
        width: 100%;
      `}
    }
  }

  ${media.lg`
    width: 34rem;
    height: 20rem;
  `}

  ${media.xs`
    max-width: 36rem;
    width: 100%;
  `}
`

export { Media, ModalTitle }
