import React from 'react'
import { WebinarUpcomingItem } from '..'
import { List } from './styled'

const WebinarsUpcomingList = ({ loading, dataSource }: { loading: boolean; dataSource: any }) => (
  <List
    grid={{
      // @ts-expect-error dead prop
      gutter: [32, 16],
      xs: 1,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 3,
      xxl: 3,
    }}
    loading={loading}
    dataSource={dataSource}
    renderItem={(item) => <WebinarUpcomingItem item={item} />}
  />
)

export default WebinarsUpcomingList
