import styled, { css, DefaultTheme } from 'styled-components'
import { images } from '../../../assets'
import { media } from '../../../styles'

const Menu = styled.div`
  background-color: ${({ theme }) => theme.colors.blue[200]};
  display: flex;
  align-items: center;
  position: relative;

  ${media.xxl`
    background: ${({ theme }: { theme: DefaultTheme }) => theme.colors.blue[200]} url(${images.patternKeyCircle}) repeat-x bottom / 64rem;
    display: block;
    position: absolute;
    top: 8.1rem;
    left: -3.2rem;
    right: -3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        ${({ theme }: { theme: DefaultTheme }) => theme.colors.blue[200]} 24%,
        rgba(19, 43, 75, 0.4) 170%
      );
      position: absolute;
      bottom: 0;
      left: 0;
    }
  `}

  ${media.lg`
  top: 6.8rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    left: -1.6rem;
    right: -1.6rem;
  `}
` as any

Menu.Inner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  ${media.xxl`
    border-top: 0.1rem solid ${({ theme }: { theme: DefaultTheme }) => theme.colors.border.black};
    align-items: flex-start;
    flex-direction: column;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  `}
`

Menu.Dropdown = styled.div<{ visible: boolean }>`
  height: ${({ visible }) => (visible ? 'auto' : 0)};
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  border-radius: 0.4rem;
  position: absolute;
  top: 4rem;
  right: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  overflow: hidden;
  transition: top 0.3s;

  ${({ visible }) =>
    visible &&
    css`
      top: 5.4rem;
    `}

  ${media.xxl`
    width: 100%;
    background-color: transparent;
    position: relative;
    top: 0;
    border: 0;
  `}
`

Menu.Dropdown.Item = styled.div`
  position: relative;

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};

    ${media.xxl`
      width: 100%;
      background-color: transparent;
      position: relative;
      top: 0;
      border: 0;
    `}
  }

  a {
    width: 100%;
    color: ${({ theme }) => theme.colors.gray[600]};
    display: block;
    font-weight: 600;
    padding: 1.6rem 1.6rem 1.6rem 4.4rem;
    white-space: nowrap;

    &:hover {
      color: ${({ theme }) => theme.colors.red[200]};
    }

    ${media.xxl`
      color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.gray[100]};
      padding: 0 0 0 3.6rem;
      font-weight: 400;
      margin-top: 1.6rem;
    `}
  }
`

Menu.Dropdown.Icon = styled.div<{ src: string }>`
  width: 2rem;
  height: 2rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 1.6rem;
  transform: translateY(-50%);

  ${media.xxl`
    left: 0;
  `}
`

Menu.Dropdown.Toggle = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.gray[100]};
  }

  ${media.xxl`
    margin-bottom: .8rem;
  `}
`

Menu.Item = styled.div<{ active: boolean }>`
  margin-right: 3.2rem;
  position: relative;

  & > a {
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 8px;

    &:not(:hover) {
      color: ${({ theme }) => theme.colors.gray[100]};
    }
  }

  ${Menu.Dropdown} {
    top: 4rem;

    ${media.xxl`
       top: 0;
    `}
  }

  ${({ active }) =>
    active &&
    css`
      a {
        color: ${({ theme }) => theme.colors.red[200]} !important;
      }
    `}

  ${media.xxl`
    border: 0;
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  `}
`

Menu.Link = styled.a`
  .anticon {
    margin-left: 5px;
  }
`

Menu.Badge = styled.div`
  display: flex;
  padding: 3px 4px 1px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.red[300]};
  color: #fff;
  font-family: Sora;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
`

// eslint-disable-next-line import/prefer-default-export
export { Menu }
