import { Typography } from 'antd'
import styled from 'styled-components'
import { images } from '../../../../../assets'
import { media } from '../../../../../styles'

const { Title } = Typography

const Banner = styled.div`
  background: ${({ theme }) => theme.colors.blue[200]} url(${images.patternKeyCircle}) repeat-x bottom / 64rem;
  color: ${({ theme }) => theme.colors.gray[100]};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 3.2rem 3.2rem 12.8rem 3.2rem;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, ${({ theme }) => theme.colors.blue[200]} 24%, rgba(19, 43, 75, 0.4) 170%);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${media.lg`
    padding: 1.6rem 1.6rem 7.2rem 1.6rem;
  `}
` as any

Banner.Circle = styled.div`
  position: absolute;
  right: -17rem;
  bottom: -17rem;

  ${media.lg`
    right: -4.6rem;
    bottom: -4.6rem;
  `}
`

Banner.SemiCircle = styled.div`
  position: absolute;
  left: calc(50% + 12rem);
  top: -12rem;

  ${media.lg`
    display: none;
  `}
`

Banner.SemiRing = styled.div`
  position: absolute;
  left: -8rem;
  bottom: -1.6rem;

  ${media.lg`
    left: -2rem;
  `}
`

Banner.Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  h1.ant-typography {
    color: ${({ theme }) => theme.colors.gray[100]};
    padding-bottom: 4rem;
    position: relative;

    &:before {
      content: '';
      width: 8.8rem;
      height: 0.1rem;
      background-color: #365c91;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    ${media.lg`
      font-size: 4rem;
      padding-bottom: 3.2rem;
    `}
  }

  .ant-btn {
    margin-top: 1.2rem;

    ${media.lg`
      margin-top: .4rem;
    `}
  }
`

Banner.Text = styled.div`
  max-width: 64rem;
  font-size: 2.4rem;
  font-weight: 300;
  margin: 2.8rem 0 1.2rem 0;

  strong {
    font-weight: 600;
  }

  ${media.lg`
    font-size: 2rem;
    margin: 1.6rem 0;
  `}
`

Banner.Title = styled(Title)`
  padding-bottom: 2.8rem !important;
`

// eslint-disable-next-line import/prefer-default-export
export { Banner }
