import { List as ListLibrary } from 'antd'
import styled from 'styled-components'

const List = styled(ListLibrary)`
  position: relative;
  z-index: 1;
`

// eslint-disable-next-line import/prefer-default-export
export { List }
